export const i18nDefaultValues = {
  WhoIssuesPolicy: {
    title: 'Кто оформляет полис',
    subTitle: '',
    labels: {
      phone: 'Телефон',
      client: 'Я клиент СК «Пульс»',
      surname: 'Фамилия',
      name: 'Имя',
      middleName: 'Отчество',
      birthDate: 'Дата рождения',
      email: 'Email',
      forMe: 'Оформляю полис для себя',
    },
    hints: {
      sendByMail: 'На эту почту мы направим полис',
    },
    errors: {
      notFilled: 'Поле не заполнено',
      maxLength: 'Максимальная длина 50 символов',
      useCyrillic: 'Используйте кириллицу',
      nameStartWithLetter: 'Имя должно начинаться с буквы',
      lastnameStartWithLetter: 'Фамилия должна начинаться с буквы',
      middleNameStartWithLetter: 'Отчество должно начинаться с буквы',
      incorrectEmail: 'Некорректный адрес электронной почты',
      enterEmail: 'Укажите электронную почту',
      insuranceFromAgeEighteen: 'Страховку можно оформить только с 18 лет',
      enterPhone: 'Введите номер телефона',
      wrongPhone: 'Неверный номер телефона',
    },
  },
};
