import { startOfTomorrow } from 'date-fns';

export const setEndDateForPolicy = (
  start: Date | undefined,
  hasPolicy: boolean
) => {
  if (hasPolicy) {
    const tommorow = startOfTomorrow();
    const endDate = start ? new Date(start) : tommorow;
    endDate.setFullYear(endDate.getFullYear() + 1);
    endDate.setDate(endDate.getDate() - 1);
    return endDate;
  }

  return null;
};
