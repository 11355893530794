export const i18nDefaultValues = {
  WhoAndHow: {
    title: 'Кто едет в путешествие?',
    subTitle: 'Выберите до {{count}} человек',
    headers: {
      indicateAge: 'Укажите возраст',
    },
    labels: {
      sumInsured: 'Страховое покрытие на каждого',
      ageTravelers: 'Возраст путешественников',
      apply: 'Применить',
      traveler: 'Путешественник',
      enterSportName: 'Введите название спорта',
      planPlaySports: 'Планирую заниматься спортом',
    },
    hints: {
      selectedSports:
        'Выбранные виды спорта действуют на каждого путешественника в рамках одного полиса',
    },
    errors: {
      ageTravelers: 'Укажите возраст путешественников',
      leastOneSport: 'Выберите хотя бы один вид спорта',
      requiredField: 'Поле обязательно для заполнения',
      ageNotMatch: 'Возраст путешественников не соответствует условию продукта',
    },
  },
};
