import { array, boolean, date, object, string } from 'yup';
import { format, differenceInDays, addDays, startOfDay } from 'date-fns';
import { getNumberOfDays, validatePeriod } from '@shared/utils';
import i18n from '@app/i18n/config';

import { i18nDefaultValues } from '../i18n';

type TravelDatesType = {
  start?: Date;
  end?: Date | null;
  duration?: string;
};

const periodValidationTraveling = (value: TravelDatesType, context: any) => {
  const {
    parent: { traveling },
    options: {
      context: { insurancePeriodShortMax, insurancePeriodShortMin },
    },
    path,
    createError,
  } = context;

  if (traveling && value?.start && value?.end) {
    const isValidPeriod = validatePeriod({
      min: insurancePeriodShortMin,
      max: insurancePeriodShortMax,
      start: String(value.start),
      end: String(value.end),
    });

    if (!isValidPeriod) {
      const start = value.start;
      const correctDate = new Date(value.start);
      correctDate.setDate(
        start.getDate() + (getNumberOfDays(insurancePeriodShortMax) || 0) - 1
      );

      return createError({
        path,
        message: `${i18n.t(
          'SMART:WhereAndHowLong.errors.endDateMustEarlier',
          i18nDefaultValues.WhereAndHowLong.errors.endDateMustEarlier
        )} ${format(correctDate, 'dd.MM.yyyy')}`,
      });
    }

    return true;
  }

  return true;
};

const periodValidationPolicy = (
  endDate: Date | null | undefined,
  context: any
) => {
  const {
    parent: { start },
    options: {
      context: { insurancePeriodOneTimeMin, insurancePeriodOneTimeMax },
    },
    path,
    createError,
  } = context;

  const isValidPeriod = validatePeriod({
    min: insurancePeriodOneTimeMin,
    max: insurancePeriodOneTimeMax,
    start: String(start),
    end: String(endDate),
  });

  if (!isValidPeriod && start) {
    const correctDate = new Date(start);
    correctDate.setDate(
      correctDate.getDate() + (getNumberOfDays(insurancePeriodOneTimeMax) || 0)
    );

    return createError({
      path,
      message: `${i18n.t(
        'SMART:WhereAndHowLong.errors.endDateMustEarlier',
        i18nDefaultValues.WhereAndHowLong.errors.endDateMustEarlier
      )} ${format(correctDate, 'dd.MM.yyyy')}`,
    });
  }

  return true;
};

const validateStartDateForTraveling = (value: any, context: any) => {
  const {
    options: {
      context: { effectiveSinceFranchise },
    },
    path,
    createError,
  } = context;

  if (value) {
    const startDate = value;
    const minStartDateForTraveling = startOfDay(new Date());
    const minCountDays = (getNumberOfDays(effectiveSinceFranchise) || 1) - 1;
    minStartDateForTraveling.setDate(
      minStartDateForTraveling.getDate() + minCountDays
    );
    if (startDate.getTime() < minStartDateForTraveling.getTime()) {
      return createError({
        path,
        message: `${i18n.t(
          'SMART:WhereAndHowLong.errors.startDateShouldLater',
          i18nDefaultValues.WhereAndHowLong.errors.startDateShouldLater
        )} ${format(minStartDateForTraveling, 'dd.MM.yyyy')}`,
      });
    }

    return true;
  }

  return true;
};

const validateEndDate = (end: Date | null | undefined, context: any) => {
  const {
    parent: { start },
    path,
    createError,
  } = context;
  if (start && end) {
    if (start.getTime() > end.getTime()) {
      return createError({
        path,
        message: `${i18n.t(
          'SMART:WhereAndHowLong.errors.endDateMustNotEarlier',
          i18nDefaultValues.WhereAndHowLong.errors.endDateMustNotEarlier
        )} ${format(start, 'dd.MM.yyyy')}`,
      });
    }
  }

  return true;
};

const validateTravelingEndDate = (
  end: Date | null | undefined,
  context: any
) => {
  const {
    parent: { start },
    options: {
      context: { insurancePeriodShortMax },
    },
    path,
    createError,
  } = context;
  if (start && end) {
    const maxDate = getNumberOfDays(insurancePeriodShortMax) || 0;
    const dayDifference = differenceInDays(end, start);

    if (dayDifference >= maxDate) {
      const targetEndDate = addDays(start, maxDate);

      return createError({
        path,
        message: `${i18n.t(
          'SMART:WhereAndHowLong.errors.endDateMustEarlier',
          i18nDefaultValues.WhereAndHowLong.errors.endDateMustEarlier
        )} ${format(targetEndDate, 'dd.MM.yyyy')}`,
      });
    }
  }

  return true;
};

const validateIsDate = (value: Date | null | undefined, context: any) => {
  const { path, createError } = context;
  const isValid = value instanceof Date;
  if (isValid) {
    return true;
  }

  return createError({
    path,
    message: i18n.t(
      'SMART:WhereAndHowLong.errors.requiredEndDate',
      i18nDefaultValues.WhereAndHowLong.errors.requiredEndDate
    ),
  });
};

export const country = object({
  label: string(),
  value: string(),
}).required();

export const getTravelDates = () =>
  object({
    start: date().required(
      i18n.t(
        'SMART:WhereAndHowLong.errors.requiredStartDate',
        i18nDefaultValues.WhereAndHowLong.errors.requiredStartDate
      )
    ),
    end: date()
      .nullable()
      .test('is-date', validateIsDate)
      .test('start-date-more-end-date', validateEndDate)
      .test('period-policy', periodValidationPolicy),

    duration: string(),
  })
    .when('policy', {
      is: true,
      then: () =>
        object({
          duration: string().required(
            i18n.t(
              'SMART:WhereAndHowLong.errors.requiredDuration',
              i18nDefaultValues.WhereAndHowLong.errors.requiredDuration
            )
          ),
          start: date().required(
            i18n.t(
              'SMART:WhereAndHowLong.errors.requiredStartDate',
              i18nDefaultValues.WhereAndHowLong.errors.requiredStartDate
            )
          ),
        }),
    })
    .when('traveling', {
      is: true,
      then: () =>
        object({
          start: date()
            .required(
              i18n.t(
                'SMART:WhereAndHowLong.errors.requiredMessage',
                i18nDefaultValues.WhereAndHowLong.errors.requiredMessage
              )
            )
            .test('start-date-for-traveling', validateStartDateForTraveling),
          end: date()
            .required(
              i18n.t(
                'SMART:WhereAndHowLong.errors.requiredMessage',
                i18nDefaultValues.WhereAndHowLong.errors.requiredMessage
              )
            )
            .test('start-date-more-end-date', validateEndDate)
            .test(
              'traveling-start-date-more-end-date',
              validateTravelingEndDate
            ),
        }),
    })
    .test('period-traveling', periodValidationTraveling);

export const getWhereAndHowLongFormSchema = () =>
  object().shape({
    countries: array(country)
      .min(
        1,
        i18n.t(
          'SMART:WhereAndHowLong.errors.requiredCountry',
          i18nDefaultValues.WhereAndHowLong.errors.requiredCountry
        )
      )
      .required(
        i18n.t(
          'SMART:WhereAndHowLong.errors.requiredCountry',
          i18nDefaultValues.WhereAndHowLong.errors.requiredCountry
        )
      ),
    policy: boolean(),
    traveling: boolean(),
    travelDates: getTravelDates(),
    isValid: boolean(),
  });
