/* eslint-disable indent */
import type { FC } from 'react';
import { lazy, memo, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { Spinner } from '@pulse-web-ui/spinner';
import { useTheme } from '@pulse-web-ui/theme';

import { useDownloadPdf } from '@shared/hooks';
import { DOCUMENT_QUERY_KEY } from '@shared/constants';
import type { DocumentType } from '@shared/types';
import { downloadBlob, openPdf } from '@shared/utils';

import { DownloadItemContent, DownloadItemWrapper } from './components.styles';
import { isAppleSafari } from '../../utils';
import { i18nDefaultValues } from '../../i18n';

const Close = lazy(() => import('@pulse-web-ui/icons/lib/lazy/close'));
const DocumentTransparent = lazy(
  () => import('@pulse-web-ui/icons/lib/lazy/document-transparent')
);

export const ClientDocumentItem: FC<DocumentType> = memo((documentItem) => {
  const theme: any = useTheme();
  const { t } = useTranslation();

  const OrderDocumentLabel = {
    Memo: t('SMART:DocumentsList.labels.memoDocument', {
      defaultValue: i18nDefaultValues.DocumentsList.labels.memoDocument,
    }),
    Rules: t('SMART:DocumentsList.labels.rulesDocument', {
      defaultValue: i18nDefaultValues.DocumentsList.labels.rulesDocument,
    }),
    Contract: t('SMART:DocumentsList.labels.contractDocument', {
      defaultValue: i18nDefaultValues.DocumentsList.labels.contractDocument,
    }),
    KeyInfoDocument: t('SMART:DocumentsList.labels.keyInfoDocument', {
      defaultValue: i18nDefaultValues.DocumentsList.labels.keyInfoDocument,
    }),
  };
  const key = `${DOCUMENT_QUERY_KEY}-${documentItem.clientDocument}`;
  const { isLoading, refetch, error } = useDownloadPdf(
    key,
    documentItem.clientDocument,
    documentItem.token || '',
    []
  );
  const isIOS = isAppleSafari();
  const queryClient = useQueryClient();

  const onDocumentLoadHandler = () => {
    refetch().then((res) => {
      if (!isIOS) {
        openPdf(res?.data?.data as unknown as string, 'doc.pdf');
      } else {
        const blob = new Blob([res?.data?.data], { type: 'application/pdf' });
        const fileName = `${documentItem.clientDocument}.pdf`;

        downloadBlob(blob, fileName);
        queryClient.removeQueries({ queryKey: [key] });
      }
    });
  };

  return (
    <DownloadItemWrapper
      data-test={
        documentItem.index || documentItem.index === 0
          ? `Document-item-${documentItem.index + 1}`
          : ''
      }
    >
      <DownloadItemContent onClick={onDocumentLoadHandler}>
        <Suspense>
          <DocumentTransparent
            width={24}
            color={theme.colors.icon?.primary || theme.colors.text.tertiary}
          />
        </Suspense>
        <div>{OrderDocumentLabel[documentItem.clientDocumentType]}</div>
      </DownloadItemContent>
      {isLoading ? <Spinner /> : null}
      {error ? (
        <Suspense>
          <Close color="red" />
        </Suspense>
      ) : null}
    </DownloadItemWrapper>
  );
});

ClientDocumentItem.displayName = 'ClientDocumentItem';
