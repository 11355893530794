import { useGetAgeRatio as useQueryHook } from '@shared/queries';
import { useStores } from '@shared/hooks';
import { useEffect } from 'react';

export const useGetAgeRatio = () => {
  const {
    MainStore: {
      initProductStore: { initState },
      productStore: { ageRatios, setAgeRatios },
    },
  } = useStores();

  const {
    data: resAgeRatio,
    isLoading: isLoadingAgeRation,
    refetch: refetchAgeRatio,
  } = useQueryHook({
    productVersion: initState?.code,
  });

  useEffect(() => {
    if (!ageRatios && initState?.code) {
      refetchAgeRatio();
    }
  }, [initState?.code]);

  useEffect(() => {
    if (!isLoadingAgeRation && resAgeRatio) {
      setAgeRatios(resAgeRatio.ageRatios);
    }
  }, [isLoadingAgeRation, resAgeRatio]);

  return { resAgeRatio, isLoadingAgeRation, refetchAgeRatio };
};
