import { Suspense, forwardRef, type Ref, FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStores } from '@shared/hooks';
import { MockSmartComponent } from '@entities/import-smart-components/mock-smart-component';
import type { SmartComponentAdapterProps } from 'smart-components';

export const IFLFLatAdapter: FC<SmartComponentAdapterProps<unknown>> = observer(
  forwardRef(
    (
      { value, onChange, isSubmitting, fieldState },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          authStore: { disableForm },
        },
      } = useStores();

      // TODO: Удалить когда будет реальный смарт-компонент
      const options = {
        title: 'Адрес квартиры',
      };

      return (
        <Suspense>
          <MockSmartComponent
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            disabled={disableForm}
            fieldState={fieldState}
          />
        </Suspense>
      );
    }
  )
);

IFLFLatAdapter.displayName = 'IFLFLatAdapter';
