import { useEffect } from 'react';
import { useStores } from '@shared/hooks';

import type { FormBuilderUpdateHandler } from '@features/form-builder';

export const useHandleSubmitOnPaymentPage = (
  updateFormValue: FormBuilderUpdateHandler
) => {
  const {
    MainStore: {
      applicationStore: {
        activeStep,
        paymenStep,
        setPaymenStep,
        flowConfig: { maxSteps },
      },
      initProductStore: { setInitOrder },
      productStore: { formState },
      authStore: { setDisableForm },
    },
  } = useStores();

  useEffect(() => {
    if (activeStep !== maxSteps && paymenStep) {
      const documentListValues = formState?.DocumentsList || {};
      setPaymenStep(false);
      updateFormValue('DocumentsList', {
        ...documentListValues,
        acceptRequirements: false,
        isValid: false,
      });
      updateFormValue('B2P', {
        initOrder: undefined,
        showInitOrder: false,
        isValid: true,
      });
      setDisableForm(false);
      setInitOrder(undefined);
      setPaymenStep(false);
    }
  }, [activeStep]);
};
