import { useRequest, useStores } from '@shared/hooks';
import type { GetCountryResponse } from '@shared/types';
import { Endpoints } from '@shared/constants';
import { useEffect } from 'react';

export const useHandleTerritory = () => {
  const {
    MainStore: {
      initProductStore: { initState },
    },
  } = useStores();

  const {
    isLoading: isLoadingCountry,
    res: resCountry,
    refetch: refetchCountry,
  } = useRequest<GetCountryResponse>(
    'insurance-TERRITORY',
    'post',
    Endpoints.TERRITORY,
    {
      baseParameters: {
        productVersion: initState?.code,
      },
    },
    [initState?.code],
    true
  );

  useEffect(() => {
    if (initState?.code && !initState?.territories) {
      refetchCountry();
    }
  }, [initState?.code, initState?.territories]);

  return { isLoadingCountry, resCountry, refetchCountry };
};
