import { PlatformType } from '@shared/constants';
import { default as Axios } from 'axios';
import type { RawAxiosRequestHeaders } from 'axios';
import { v4 as uuidv4 } from 'uuid';

export const refreshToken = () => {
  let headers: RawAxiosRequestHeaders = {
    'X-Process-ID': uuidv4(),
    'Content-type': 'application/json',
    'X-Platform-Type': PlatformType,
  };

  const csrfToken = localStorage.getItem('csrfToken');

  if (csrfToken) {
    headers = {
      ...headers,
      'X-CSRF-Token': csrfToken,
    };
  }

  return Axios.post(
    `${window.envUrls.BASEURL}/v1/user/refresh-token`,
    {},
    {
      withCredentials: true,
      headers,
    }
  );
};
