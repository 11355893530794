import { media, styled } from '@pulse-web-ui/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px 16px;
  border-radius: 24px;

  ${media.desktop} {
    padding: 24px;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: flex-start;
`;
