import { Button } from '@pulse-web-ui/button';
import { media, styled } from '@pulse-web-ui/theme';

type ItemCardContentProps = {
  isError?: boolean;
};

export const FooterWrapper = styled.div`
  ${({ theme }) => theme.common['box-shadow']};
  display: flex;
  gap: 10px;
  background: #fff;
  padding: 16px;
  border-radius: 16px;
  justify-content: space-between;
  ${media.desktop} {
    padding: 24px;
    gap: 32px;
    justify-content: flex-start;
  }
`;

export const ItemCardWrapper = styled.div`
  max-width: 85%;
  margin-bottom: 8px;

  &:last-child {
    max-width: none;
    margin-bottom: 0;
  }

  ${media.desktop} {
    max-width: 60%;
  }
`;

export const ItemCardHeader = styled.h3`
  font-weight: 400;
  white-space: nowrap;
  font-size: 12px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const ItemCardContent = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text.primary};
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ItemCardPrice = styled.div<ItemCardContentProps>`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme, isError }) =>
    isError ? theme.colors.text.error : theme.colors.text.primary};
  width: 100%;
  white-space: nowrap;
`;

export const ItemCardAction = styled.span`
  display: inline-block;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.interactive};
`;

export const MobileView = styled.div`
  ${media.desktop} {
    display: none;
  }
`;
export const DesktopView = styled.div`
  display: none;
  ${media.desktop} {
    max-width: 20%;
    display: initial;
  }
`;

export const DateView = styled.div`
  display: none;
  ${media.desktop} {
    max-width: none;
    display: initial;
  }
`;

export const StyledCardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledCardListItem = styled.div`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledButton = styled(Button)`
  border-radius: 8px;
`;
