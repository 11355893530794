import { analyticEvents } from '@app/web-analytic';
import { useStores } from '@shared/hooks';
import { useEffect } from 'react';

export const useTravelStartEvent = (isDirty: boolean) => {
  const {
    MainStore: {
      applicationStore: { sendSingleAnalyticEvent },
    },
  } = useStores();

  useEffect(() => {
    if (isDirty) {
      sendSingleAnalyticEvent(analyticEvents.travelStart);
    }
  }, [isDirty]);
};
