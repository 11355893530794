import { SmartCheckPolicy } from '@entities/import-smart-components/smart-check-policy';
import type { CheckPolicyValues } from '@pulse-smart-components-kit/check-policy';
import { FallbackSkeleton } from '@shared/components';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, type Ref, FC } from 'react';
import type { SmartComponentAdapterProps } from 'smart-components';
import { formCheckPolicySchema } from './schema';
import { getMock } from './mock';
// import { Trans, useTranslation } from 'react-i18next';
// import { TERMS_POLICY } from './constants';
// import { i18nDefaultValues } from './i18n';

export const CheckPolicyAdapter: FC<
  SmartComponentAdapterProps<CheckPolicyValues>
> = observer(
  forwardRef(
    (
      { value, onChange, isSubmitting, fieldState },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      // const { handleDownloadClick, documentsStatusList } = useHandleDownloadPDF(
      //   accessToken || '',
      //   orderData?.clientDocument
      // );

      // const { t } = useTranslation();

      return (
        <Suspense fallback={<FallbackSkeleton height={460} />}>
          <SmartCheckPolicy
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={getMock()}
            fieldState={fieldState}
            formSchema={formCheckPolicySchema}
          />
        </Suspense>
      );
    }
  )
);

CheckPolicyAdapter.displayName = 'CheckPolicyAdapter';
