import { media, styled, typography } from '@pulse-web-ui/theme';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px 16px;
  border-radius: 24px;
  gap: 16px;

  & [data-orientation='horizontal'] {
    height: 2px !important;
  }

  & [data-reach-slider-track] {
    top: -6px;
  }

  ${media.desktop} {
    padding: 24px;
    gap: 24px;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.desktop} {
    gap: 16px;
  }
`;

export const StyledSubHeaderLink = styled.span`
  color: ${({ theme }) => theme?.colors?.text?.interactive};
  cursor: pointer;
`;

export const StyledMoreBox = styled.div`
  ${typography.regular18}
  line-height: 26px;
  color: ${({ theme }) => theme?.colors?.text?.interactive};
  cursor: pointer;
  padding-top: 8px;
`;

export const StyledRadioLabel = styled.div`
  padding-bottom: 12px;
  ${typography.regular14}
  line-height: 20px;
  color: ${({ theme }) => theme?.colors?.text?.primary};
`;
