import { SCHENGEN } from '../constants';

import type { Territory } from '@shared/types';

export const getSchengenCountries = (territories: Territory[] | undefined) => {
  if (territories) {
    return territories.filter(
      (territory) =>
        territory.code === SCHENGEN || territory?.groupCode === SCHENGEN
    );
  }

  return [];
};
