/* eslint-disable indent */
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { i18nDefaultValues } from '../../i18n';

import { Submit } from './submit-button.styles';
import type { Props } from './submit-button.types';

export const SubmitButton: FC<Props> = ({
  onClick,
  buttonText,
  currentStep,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Submit
      disabled={disabled}
      isSecondStep={currentStep === 1}
      onClick={onClick}
      data-test="Submit"
    >
      {buttonText
        ? buttonText
        : t('SMART:Submit.labels.continue', {
            defaultValue: i18nDefaultValues.Submit.labels.continue,
          })}
    </Submit>
  );
};
