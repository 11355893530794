import { useTranslation } from 'react-i18next';

import {
  Container,
  CountIcon,
  DeleteTraveler,
  NumberTraveler,
  TitleTraveler,
} from './title.styles';
import { i18nDefaultValues } from '../../i18n';

import type { FC } from 'react';
import type { Props } from './title.types';

export const Title: FC<Props> = ({ serialNo, fieldsLength, remove }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <NumberTraveler>
        <CountIcon>{serialNo}</CountIcon>
        <TitleTraveler>
          {t('SMART:WhoAndHowPaperwork.headers.traveler', {
            defaultValue: i18nDefaultValues.WhoAndHowPaperwork.headers.traveler,
          })}
        </TitleTraveler>
      </NumberTraveler>
      {fieldsLength !== 1 && (
        <DeleteTraveler variant="text" onClick={remove}>
          {t('SMART:WhoAndHowPaperwork.labels.delete', {
            defaultValue: i18nDefaultValues.WhoAndHowPaperwork.labels.delete,
          })}
        </DeleteTraveler>
      )}
    </Container>
  );
};
