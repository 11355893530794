/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState, forwardRef, memo, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';

import { Calendar, Country, Policy, Traveling, Warning } from './components';
import { getWhereAndHowLongFormSchema } from './schema';
import { getSchengenCountries, setEndDateForPolicy } from './utils';

import {
  Form,
  FormWrapper,
  WarningWrapper,
  Row,
} from './where-and-how-long.style';

import type {
  WhereAndHowLongOptions,
  WhereAndHowLongValues,
} from './where-and-how-long.type';
import { i18nDefaultValues } from './i18n';
import type { FC, Ref } from 'react';
import type { SmartComponentProps } from 'smart-components';

const CODE_RUSSIA = '643';

export enum WhereAndHowLongAnalyticEvent {
  ON_DATES_SELECT,
  ON_COUNTRY_SELECT,
}

const WhereAndHowLong: FC<
  SmartComponentProps<
    WhereAndHowLongValues,
    WhereAndHowLongOptions,
    WhereAndHowLongAnalyticEvent
  >
> = memo(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        options: {
          insurancePeriodShortMax,
          insurancePeriodShortMin,
          effectiveSinceFranchise,
          insurancePeriodOneTimeMax,
          insurancePeriodOneTimeMin,
          insurancePeriodAnnual,
          territories,
          startDate,
          endDate,
        },
        onAnalyticEventSend,
      },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const { t } = useTranslation();
      const [alerts, setAlerts] = useState<string[]>([]);
      const formSchema = useMemo(() => getWhereAndHowLongFormSchema(), [t]);

      const {
        trigger,
        control,
        watch,
        setValue,
        getValues,
        formState: { isValid },
        setError,
      } = useForm<WhereAndHowLongValues>({
        defaultValues: value,
        resolver: yupResolver(formSchema),
        mode: 'all',
        context: {
          insurancePeriodShortMax,
          insurancePeriodShortMin,
          effectiveSinceFranchise,
          insurancePeriodOneTimeMax,
          insurancePeriodOneTimeMin,
        },
      });

      const schengenCountries = getSchengenCountries(territories);

      const policy = watch('policy');
      const traveling = watch('traveling');
      const travelDatesStart = watch('travelDates.start');
      const travelDatesEnd = watch('travelDates.end');

      useEffect(() => {
        if (policy === true) {
          addAlertsHandler('policy');
        }
        if (policy === false) {
          setValue('travelDates.duration', '');
          clearAlertsHandler('policy');
        }
        if (
          (traveling === false || !traveling) &&
          (policy === false || !policy) &&
          travelDatesStart &&
          travelDatesEnd
        ) {
          addAlertsHandler('noChecked');
        } else clearAlertsHandler('noChecked');
      }, [traveling, policy, travelDatesStart, travelDatesEnd]);

      const clearAlertsHandler = (alert: string) => {
        setAlerts((prevState) => {
          if (prevState.includes(alert)) {
            const clone = [...prevState];
            clone.splice(prevState.indexOf(alert), 1);

            return clone;
          }

          return [...prevState];
        });
      };

      const addAlertsHandler = (alert: string) => {
        setAlerts((prevState) => {
          if (prevState.includes(alert)) {
            return prevState;
          } else {
            return [...prevState, alert];
          }
        });
      };

      useEffect(() => {
        const subscription = watch((values) => {
          onChange(values as WhereAndHowLongValues);
        });

        return () => subscription.unsubscribe();
      }, [watch]);

      useEffect(() => {
        const countries = getValues('countries') || [];
        const countriesCode = countries.map((country) => country.value);
        const hasRussia = countriesCode.includes(CODE_RUSSIA);
        const hasSchengen = schengenCountries.find((country) =>
          countriesCode.includes(country.code)
        );

        if (hasRussia) {
          addAlertsHandler('isRussia');
        } else {
          clearAlertsHandler('isRussia');
        }

        if (hasSchengen) {
          addAlertsHandler('isShengen');
        } else {
          clearAlertsHandler('isShengen');
        }
      }, [watch('countries')]);

      useEffect(() => {
        if (isSubmitting) {
          trigger();
        }
      }, [isSubmitting]);

      useEffect(() => {
        if (travelDatesStart && travelDatesEnd) {
          trigger('travelDates');
        }
      }, [traveling]);

      useEffect(() => {
        setValue('isValid', isValid);
      }, [isValid]);

      const handleChangePolicy = (checked: boolean) => {
        setValue('policy', checked);
        setValue('traveling', false);

        const travelDates = getValues('travelDates');
        const end = setEndDateForPolicy(travelDates?.start, checked);

        const duration =
          insurancePeriodAnnual && checked
            ? String(insurancePeriodAnnual[0])
            : undefined;

        setValue('travelDates', {
          ...travelDates,
          duration,
          end,
        });

        if (!checked) {
          setError('travelDates.end', {
            message: t('SMART:WhereAndHowLong.errors.requiredEndDate', {
              defaultValue:
                i18nDefaultValues.WhereAndHowLong.errors.requiredEndDate,
            }),
          });
        }

        if (checked && travelDates?.start) {
          onAnalyticEventSend &&
            onAnalyticEventSend(WhereAndHowLongAnalyticEvent.ON_DATES_SELECT);
        }
      };

      return (
        <Form ref={forwardRef}>
          <FormWrapper data-test="Destination-block">
            <HeaderWithSubText
              title={t('SMART:WhereAndHowLong.title', {
                defaultValue: i18nDefaultValues.WhereAndHowLong.title,
              })}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <div data-test="Travel-country">
                  <Country
                    value={field.value}
                    onChange={field.onChange}
                    error={error}
                    territories={territories}
                    onAnalyticEventSend={onAnalyticEventSend}
                  />
                </div>
              )}
              control={control}
              name="countries"
            />

            <Row>
              <Controller
                render={({ field }) => (
                  <Policy
                    value={Boolean(field.value)}
                    onChange={handleChangePolicy}
                  />
                )}
                control={control}
                name="policy"
              />
              {getValues('policy') ? null : (
                <Controller
                  render={({ field }) => (
                    <Traveling
                      value={Boolean(field.value)}
                      onChange={field.onChange}
                    />
                  )}
                  control={control}
                  name="traveling"
                />
              )}
            </Row>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <Calendar
                  value={field.value}
                  onChange={field.onChange}
                  error={error}
                  policy={Boolean(getValues('policy'))}
                  traveling={Boolean(getValues('traveling'))}
                  startDate={startDate}
                  endDate={endDate}
                  insurancePeriodAnnual={insurancePeriodAnnual}
                  effectiveSinceFranchise={effectiveSinceFranchise}
                  onAnalyticEventSend={onAnalyticEventSend}
                />
              )}
              control={control}
              name="travelDates"
            />
          </FormWrapper>
          {alerts?.length > 0 ? (
            <WarningWrapper>
              <Warning
                warnings={alerts}
                options={{ insurancePeriodOneTimeMax }}
              />
            </WarningWrapper>
          ) : null}
        </Form>
      );
    }
  )
);

export default WhereAndHowLong;
