/* eslint-disable indent */
export const getMessageErrorForStartDate = (error: any) => {
  const type = error?.type || error?.start?.type;
  const message = error?.message || error?.start?.message;

  switch (type) {
    case 'optionality':
      return message;

    case 'start-date-for-traveling':
      return message;

    default:
      return '';
  }
};
