import { array, boolean, object, string } from 'yup';

export const riskSchema = object().shape({
  code: string().required(),
  name: string().required(),
  active: boolean().required(),
  binding: boolean().required(),
  description: string(),
  imageUrl: string(),
  extendedDescription: string(),
  switchability: boolean(),
});

export const formInsuranceRisksSchema = object().shape({
  risks: array(riskSchema).test('oneActive', 'Required', (risks) =>
    risks?.some((risk) => risk.active)
  ),
  isValid: boolean(),
});
