import {
  AuthStore,
  ApplicationStore,
  ThemesStore,
  SmartComponentAdditionalOptionsStore,
  ErrorStore,
  ProductStore,
  InitProductStore,
} from '@entities/index';
import { configure, makeAutoObservable } from 'mobx';
import { FlatGlobalVariables } from 'mock';

configure({
  enforceActions: 'always',
  computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: false,
  disableErrorBoundaries: true,
});

class Main {
  public authStore: AuthStore;
  public themesStore: ThemesStore;
  public applicationStore: ApplicationStore<FlatGlobalVariables>;
  public smartComponentAdditionalOptionsStore: SmartComponentAdditionalOptionsStore;
  public errorStore: ErrorStore;
  public productStore: ProductStore;
  public initProductStore: InitProductStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.themesStore = new ThemesStore(this);
    this.applicationStore = new ApplicationStore();
    this.smartComponentAdditionalOptionsStore =
      new SmartComponentAdditionalOptionsStore();
    this.errorStore = new ErrorStore();
    this.productStore = new ProductStore(this);
    this.initProductStore = new InitProductStore();
    makeAutoObservable(this);
  }
}

export { Main };
