import { useWindowSize } from '@shared/hooks';

const MAX_WIDTH_FOR_DESKTOP = 744;
const MAX_WIDTH = 1024;
const PADDING = 48;

export const useModalWidth = () => {
  const [width] = useWindowSize();

  return width > MAX_WIDTH ? MAX_WIDTH_FOR_DESKTOP : width - PADDING;
};
