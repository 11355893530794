export const i18nDefaultValues = {
  DocumentsList: {
    title: 'Ваши документы',
    labels: {
      memoDocument: 'Памятка',
      rulesDocument: 'Правила страхования',
      contractDocument: 'Страховой полис',
      keyInfoDocument: 'Ключевой информационный документ',
      includedInPolicy: 'В полис включено',
      acceptTermsPolicy:
        'Принимаю условия страхового полиса, правил страхования, ключевого информационного документа',
    },
    errors: {
      acceptRequirements: 'Без этого согласия не можем оформить страховку',
    },
  },
};
