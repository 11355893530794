import { useQuery } from 'react-query';
import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';

import type { GetAgeRatioRequest, GetAgeRatioResponse } from './types';

export const useGetAgeRatio = (data: GetAgeRatioRequest) => {
  const fetch = fetchData<GetAgeRatioResponse>({
    url: `${Endpoints.GET_AGE_RATION}/${data.productVersion}`,
    method: 'POST',
  });

  const queryResult = useQuery(['get-age-ratio', data], fetch, {
    enabled: false,
  });

  return queryResult;
};
