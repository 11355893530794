import { useMemo, type FC, memo } from 'react';
import {
  ItemCardHeader,
  ItemCardPrice,
  ItemCardWrapper,
} from './footer-smart-component.styles';
import type { ProductPrice } from '@shared/types';
import { UnicodeEnum } from '@shared/constants';
import { renderPrice } from './footer-smart-component.utils';
import { useTranslation } from 'react-i18next';
import { i18nDefaultValues } from './i18n';

type ItemCardType = {
  price: ProductPrice;
  errorState?: boolean;
  activeStep: number;
  maxSteps: number | undefined;
};

export const ItemPrice: FC<ItemCardType> = memo(
  ({ price, errorState, activeStep, maxSteps }) => {
    const { t } = useTranslation();

    const priceWithDiscount = useMemo(
      () =>
        renderPrice(
          Number(
            price.isSuccessfulPromo
              ? price.premiumAndDeltaPromo
              : price.premiumAndDelta || 0
          ),
          activeStep,
          maxSteps
        ),
      [
        activeStep,
        price.isSuccessfulPromo,
        price.premiumAndDelta,
        price.premiumAndDeltaPromo,
      ]
    );

    return (
      <ItemCardWrapper data-test="Price">
        <ItemCardHeader>
          {t('SMART:FooterSmartComponent.headers.policyPrice', {
            defaultValue:
              i18nDefaultValues.FooterSmartComponent.headers.policyPrice,
          })}
        </ItemCardHeader>
        <ItemCardPrice isError={errorState}>
          {`${priceWithDiscount}${UnicodeEnum.NBSP}₽`}
        </ItemCardPrice>
      </ItemCardWrapper>
    );
  }
);

ItemPrice.displayName = 'ItemPrice';
