import { media, styled } from '@pulse-web-ui/theme';

export const CalendarStyle = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-direction: column;

  ${media.desktop} {
    flex-direction: row;
    gap: 16px;
  }
`;

export const CalendarItem = styled.div`
  flex: 1;
`;
