import { useEffect } from 'react';
import { useStores } from '@shared/hooks';
import { Endpoints, PRICES_MAX_VALUE } from '@shared/constants';
import { useGetPrices } from './use-get-prices';
import { getData } from './utils';
import { ErrorCode, hasError } from '@shared/utils';

export const useHandleGetPrice = () => {
  const {
    MainStore: {
      initProductStore: { insuranceLimitStart, initState },
      errorStore: { displayGlobalErrorUrl, setErrorRetry },
      productStore: {
        ageRange,
        formState,
        currency,
        setPrice,
        setHasPricesCalcErr,
        price: { promoCode },
      },
    },
  } = useStores();

  const options = {
    traveling: Boolean(formState?.WhereAndHowLong?.traveling),
    effectiveSinceFranchise: insuranceLimitStart?.effectiveSinceFranchise,
    insurancePeriodOneTimeMin: insuranceLimitStart?.insurancePeriodOneTimeMin,
    insurancePeriodOneTimeMax: insuranceLimitStart?.insurancePeriodOneTimeMax,
    insurancePeriodShortMax: insuranceLimitStart?.insurancePeriodShortMax,
    ageRange: ageRange,
    hasError: displayGlobalErrorUrl?.includes(Endpoints.GET_PRICES),
  };

  const { isLoading, error, data, refetch } = useGetPrices(
    getData(formState, initState, currency, promoCode),
    options
  );

  useEffect(() => {
    if (!isLoading && data) {
      setErrorRetry(false);
      setPrice(data);
      setHasPricesCalcErr(false);
    }

    if (hasError(error, ErrorCode.BusinessError)) {
      setHasPricesCalcErr(true);
      setPrice({
        premiumAndDelta: String(PRICES_MAX_VALUE),
        premiumAndDeltaPromo: String(PRICES_MAX_VALUE),
      });
    }
  }, [isLoading, data, error]);

  return refetch;
};
