import { Button } from '@pulse-web-ui/button';
import { media, styled } from '@pulse-web-ui/theme';

export const Submit = styled(Button)<{
  isSecondStep: boolean;
}>`
  /* min-width: 172px; */
  width: 100%;
  white-space: nowrap;
  padding: 16px 24px;
  ${media.desktop} {
    width: auto;
    padding: 16px 32px;
  }
`;
