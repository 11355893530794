import { useStores } from '@shared/hooks';
import { useEffect } from 'react';
import { transliterationRusToEnd } from '@shared/utils';
import type { FormBuilderUpdateHandler } from '@features/form-builder';
import { Traveler } from '@shared/types';
import { v4 as uuid } from 'uuid';

export const useHandleWhoAndHowPaperwork = (
  updateFormValue: FormBuilderUpdateHandler
) => {
  const {
    MainStore: {
      productStore: { formState, setTravelersData },
    },
  } = useStores();

  const whoIssuesPolicy = formState?.WhoIssuesPolicy;
  const isMe = whoIssuesPolicy?.forMe;
  const whoAndHowPaperwork = formState?.WhoAndHowPaperwork;

  useEffect(() => {
    const travelers: Traveler[] =
      formState?.WhoAndHowPaperwork?.travelers || [];

    const me: Traveler = {
      name: whoIssuesPolicy?.name || '',
      surname: whoIssuesPolicy?.surname || '',
      birthday: whoIssuesPolicy?.birthDate || null,
      id: travelers?.[0]?.id || uuid(),
    };
    const formattedMe: Traveler = {
      name: transliterationRusToEnd(me.name),
      surname: transliterationRusToEnd(me.surname),
      birthday: me.birthday,
      id: me.id,
    };

    if (isMe) travelers[0] = formattedMe;

    updateFormValue('WhoAndHowPaperwork', {
      ...whoAndHowPaperwork,
      travelers: [...travelers],
    });

    const updatedAges = setTravelersData(travelers);

    updateFormValue('WhoAndHow', {
      ...formState.WhoAndHow,
      ageSelect: updatedAges,
    });
  }, [isMe]);
};
