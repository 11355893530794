import type { RisksType } from '@shared/types';

export const getSlider = (item: RisksType) => {
  if (item.coverageSumByClient && !item.discreteCoverage) {
    const [min, max] = item.coverageSum;
    return {
      min,
      max,
    };
  }
  return undefined;
};
