import { useStores } from '@shared/hooks';
import { useEffect } from 'react';
import { FormBuilderUpdateHandler } from '@features/form-builder';
import { useSubmitOrder } from '@shared/queries';

export const useHandleB2P = (updateFormValue: FormBuilderUpdateHandler) => {
  const {
    MainStore: {
      productStore: { isPolicyBanned, orderData, agentLogin },
      applicationStore: {
        activeStep,
        currentStep,
        retryPayment,
        setPaymenStep,
        flowConfig: { otpStep, maxSteps },
      },
      authStore: { profile, setDisableForm, setUseOtp },
      errorStore: { setErrorRetry },
      initProductStore: { setInitOrder },
    },
  } = useStores();

  const {
    isLoading: isLoadingSubmitOrder,
    res: resSubmitOrder,
    refetch: refetchSubmitOrder,
    remove: removeSubmitOrder,
  } = useSubmitOrder(updateFormValue);

  useEffect(() => {
    setDisableForm(isLoadingSubmitOrder);
    if (isLoadingSubmitOrder && !retryPayment) {
      updateFormValue('B2P', {
        initOrder: undefined,
        showInitOrder: false,
        isValid: true,
      });
      setInitOrder(undefined);
      setPaymenStep(false);
      setErrorRetry(false);
    }
  }, [isLoadingSubmitOrder]);

  useEffect(() => {
    if (activeStep === maxSteps && !retryPayment && !agentLogin)
      refetchSubmitOrder();
  }, [activeStep]);

  useEffect(() => {
    if (isPolicyBanned) removeSubmitOrder();
  }, [isPolicyBanned]);

  useEffect(() => {
    if (currentStep === maxSteps && !orderData && !resSubmitOrder)
      setDisableForm(true);
    if (currentStep === otpStep && !profile) setUseOtp(true);
  }, [currentStep]);

  return { resSubmitOrder, refetchSubmitOrder };
};
