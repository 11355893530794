import { array, object, string, date, boolean } from 'yup';
import i18n from '@app/i18n/config';
import { valideBirthdayByPolicyStartDate } from '@shared/utils';
import { onlyLatin } from '../constants';
import { i18nDefaultValues } from '../i18n';

const requiredMessage = i18n.t(
  'SMART:WhoAndHowPaperwork.errors.requiredField',
  i18nDefaultValues.WhoAndHowPaperwork.errors.requiredField
);

const travelerFormatNameMessage = i18n.t(
  'SMART:WhoAndHowPaperwork.errors.useLatin',
  i18nDefaultValues.WhoAndHowPaperwork.errors.useLatin
);

const maxLength = i18n.t(
  'SMART:WhoAndHowPaperwork.errors.maxLength',
  i18nDefaultValues.WhoAndHowPaperwork.errors.maxLength
);

const acceptableAgeMessage = i18n.t(
  'SMART:WhoAndHowPaperwork.errors.incorrectAge',
  i18nDefaultValues.WhoAndHowPaperwork.errors.incorrectAge
);

const validateForAcceptableAge = (
  value: Date | null | undefined,
  context: any
) => {
  const {
    path,
    createError,
    options: {
      context: { ageRange, dateStart },
    },
  } = context;

  const birthday = value ? new Date(value) : new Date();

  const isValid = valideBirthdayByPolicyStartDate({
    birthday,
    policyStartDate: dateStart,
    ageRange,
  });

  if (isValid) {
    return true;
  }

  return createError({
    path,
    message: acceptableAgeMessage,
  });
};

export const traveler = object({
  name: string()
    .required(requiredMessage)
    .max(50, maxLength)
    .matches(onlyLatin, travelerFormatNameMessage),
  surname: string()
    .required(requiredMessage)
    .max(50, maxLength)
    .matches(onlyLatin, travelerFormatNameMessage),
  birthday: date()
    .nullable()
    .test('is-date', requiredMessage, (value) => value instanceof Date)
    .test('acceptable-age', validateForAcceptableAge),
  id: string().required(),
});

export const schema = object({
  travelers: array(traveler).required(),
  isValid: boolean(),
});
