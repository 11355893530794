import { array, boolean, date, object, string } from 'yup';
import i18n from '@app/i18n/config';
import { valideBirthdayByPolicyStartDate } from '@shared/utils';

import { i18nDefaultValues } from '../i18n';

export const chooseTravelsQuantityMessage = i18n.t(
  'SMART:WhoAndHow.errors.ageTravelers',
  i18nDefaultValues.WhoAndHow.errors.ageTravelers
);

const validateForAcceptableAge = (
  ageSelects: { label?: string; value?: string }[],
  context: any
) => {
  const {
    path,
    createError,
    options: {
      context: { ageRange, dateStart },
    },
  } = context;
  const policyStartDate = dateStart ? new Date(dateStart) : new Date();
  const isValid = ageSelects.every(({ value }) => {
    const birthday = new Date(policyStartDate);
    birthday.setFullYear(birthday.getFullYear() - Number(value));

    const isValid = valideBirthdayByPolicyStartDate({
      birthday,
      policyStartDate,
      ageRange,
    });
    return isValid;
  });

  if (isValid) {
    return true;
  }

  return createError({
    path,
    message: i18n.t(
      'SMART:WhoAndHow.errors.ageNotMatch',
      i18nDefaultValues.WhoAndHow.errors.ageNotMatch
    ),
  });
};

export const ageSelect = object({
  label: string(),
  value: string(),
}).required();

export const sport = object({
  label: string(),
  value: string(),
}).required();

export const travelDates = object({
  start: date().required(
    i18n.t(
      'SMART:WhoAndHow.errors.requiredField',
      i18nDefaultValues.WhoAndHow.errors.requiredField
    )
  ),
  end: date().required(
    i18n.t(
      'SMART:WhoAndHow.errors.requiredField',
      i18nDefaultValues.WhoAndHow.errors.requiredField
    )
  ),
}).required(
  i18n.t(
    'SMART:WhoAndHow.errors.requiredField',
    i18nDefaultValues.WhoAndHow.errors.requiredField
  )
);

export const getWhoAndHowSchema = () =>
  object({
    sportKinds: array(sport).when('sportHas', {
      is: true,
      then: (sportKinds) =>
        sportKinds
          .min(
            1,
            i18n.t(
              'SMART:WhoAndHow.errors.leastOneSport',
              i18nDefaultValues.WhoAndHow.errors.leastOneSport
            )
          )
          .required(
            i18n.t(
              'SMART:WhoAndHow.errors.leastOneSport',
              i18nDefaultValues.WhoAndHow.errors.leastOneSport
            )
          ),
    }),
    sportHas: boolean(),
    coverageSum: string().required(),
    ageSelect: array(ageSelect)
      .required(
        i18n.t(
          'SMART:WhoAndHow.errors.ageTravelers',
          i18nDefaultValues.WhoAndHow.errors.ageTravelers
        )
      )
      .test(
        'required',
        i18n.t(
          'SMART:WhoAndHow.errors.ageTravelers',
          i18nDefaultValues.WhoAndHow.errors.ageTravelers
        ),
        (value) => !!value.length
      )
      .test('acceptable-age', validateForAcceptableAge),
    isValid: boolean(),
  });
