import { StyledCheckbox } from '@shared/styles';
import type { FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TooltipBgColor,
  TooltipDirection,
  TriggerDirection,
} from '@pulse-web-ui/tooltip';

import { useIsDesktop } from '@shared/hooks';

import { TravelingStyle } from './traveling.style';

import { i18nDefaultValues } from '../../i18n';

import type { Props } from './traveling.type';

export const Traveling: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();

  const handleChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    onChange(target.checked);
  };
  return (
    <TravelingStyle data-test="Already-traveling">
      <StyledCheckbox
        checked={value}
        onChange={handleChange}
        hasTooltip={isDesktop}
        label={t('SMART:WhereAndHowLong.labels.alreadyTraveling', {
          defaultValue:
            i18nDefaultValues.WhereAndHowLong.labels.alreadyTraveling,
        })}
        tooltipOptions={{
          trigger: TriggerDirection.HOVER,
          direction: TooltipDirection.UP,
          tooltipText: t('SMART:WhereAndHowLong.hints.forAlreadyTraveling', {
            defaultValue:
              i18nDefaultValues.WhereAndHowLong.hints.forAlreadyTraveling,
          }),
          tooltipBgColor: TooltipBgColor.GRAY,
        }}
      />
    </TravelingStyle>
  );
};
