import { type FC, Suspense, forwardRef, type Ref } from 'react';
import { observer } from 'mobx-react-lite';

import { useStores } from '@shared/hooks';
import { MockSmartComponent } from '@entities/import-smart-components/mock-smart-component';
import type { SmartComponentAdapterProps } from 'smart-components';

export const InsurancePersonsAdapter: FC<SmartComponentAdapterProps<unknown>> =
  observer(
    forwardRef(
      (
        { value, onChange, isSubmitting, fieldState },
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const {
          MainStore: {
            authStore: { disableForm },
            productStore: {
              formState: { WhoIssuesPolicy },
            },
          },
        } = useStores();

        // TODO: Удалить когда будет реальный смарт-компонент
        const options = {
          title: 'Застрахованные',
        };

        const hasAuthorized = WhoIssuesPolicy?.hasAuthorized;

        if (!hasAuthorized) return null;

        return (
          <Suspense>
            <MockSmartComponent
              ref={forwardRef}
              value={value}
              onChange={onChange}
              isSubmitting={isSubmitting}
              options={options}
              disabled={disableForm}
              fieldState={fieldState}
            />
          </Suspense>
        );
      }
    )
  );

InsurancePersonsAdapter.displayName = 'InsurancePersonsAdapter';
