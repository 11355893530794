import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '@pulse-web-ui/input';

import { i18nDefaultValues } from '../../i18n';

import type { Props } from './input-phone.types';

export const InputPhone: FC<Props> = ({
  value,
  disabled,
  onChange,
  error,
  onBlur,
}) => {
  const { t } = useTranslation();

  return (
    <Input
      type="tel"
      label={t('SMART:WhoIssuesPolicy.labels.phone', {
        defaultValue: i18nDefaultValues.WhoIssuesPolicy.labels.phone,
      })}
      value={value}
      onChange={onChange}
      disabled={disabled}
      error={error}
      onBlur={onBlur}
    />
  );
};
