import i18n from '@app/i18n/config';
import { array, boolean, object, string } from 'yup';

export const formCheckPolicySchema = object().shape({
  documentsList: array(),
  token: string(),
  acceptRequirements: boolean()
    .nonNullable()
    .test({
      name: 'acceptRequirements',
      message: i18n.t('SMART:CheckPolicy.errors.acceptRequirements'),
      test: (value) => value === true,
    }),
  isValid: boolean(),
});
