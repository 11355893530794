import { phoneDisplayValueCasting } from '@shared/utils';

type UpdateFormByMobileAppType = (
  handler: (key: string, value: any) => void,
  deviceType: string,
  phoneFromMobileApp: string
) => void;

export const updateFormByMobileApp: UpdateFormByMobileAppType = (
  updateFormValue,
  deviceType,
  phoneFromMobileApp
) => {
  updateFormValue('WhoIssuesPolicy.showSwitcher', true);
  if (deviceType && phoneFromMobileApp) {
    const formattedPhone = phoneDisplayValueCasting(phoneFromMobileApp)
      .trim()
      .slice(2);

    updateFormValue('WhoIssuesPolicy.showSwitcher', false);
    updateFormValue('WhoIssuesPolicy.client', true);
    updateFormValue('WhoIssuesPolicy.phone', formattedPhone);
  }
};
