import type { InsuredAmountConfigurationOptions } from '@pulse-smart-components-kit/insured-amount-configuration';

type ConfigurationOptionsOrder =
  | 'DECORATION'
  | 'MOVABLES'
  | 'CONSTRUCTION'
  | 'Liability_1';

const order: Record<ConfigurationOptionsOrder, number> = {
  DECORATION: 0,
  MOVABLES: 1,
  CONSTRUCTION: 2,
  Liability_1: 3,
} as const;

export const prepareConfigurationOptions = (
  options: InsuredAmountConfigurationOptions
) => {
  const preparedSubObjects = [];

  for (const option of options.subObjects) {
    preparedSubObjects[order[option.code]] = option;
  }

  return {
    ...options,
    subObjects: preparedSubObjects,
  };
};
