import { UnicodeEnum } from '@shared/constants';

export const i18nDefaultValues = {
  WhereAndHowLong: {
    title: 'Куда и на сколько едете?',
    labels: {
      startPolicy: 'Начало действия полиса',
      startTravel: 'Начало поездки',
      dateTravel: 'Сколько дней вы будете путешествовать?',
      finishTravel: 'Окончание поездки',
      countryTravel: 'Страна или регион путешествия',
      policyOnYear: 'Полис на год',
      alreadyTraveling: 'Уже путешествую',
    },
    placeholders: {
      dateTravel: 'Сколько дней вы будете путешествовать?',
    },
    hints: {
      forAvidTravelers:
        'Для заядлых путешественников. В каждой выбранной стране полис действует не более 90 дней',
      forAlreadyTraveling:
        'Для тех, кто уже в путешествии. В рамках этой опции полис начнет действовать на 5 день после оплаты',
      numberDaysReduced:
        'После каждого путешествия количество дней защиты сокращается',
      exampleNumberDaysReduced: `Пример: Георгий выбрал годовой полис с 30${UnicodeEnum.NBSP}днями защиты и сразу полетел в Индию на 10${UnicodeEnum.NBSP}дней. После поездки у него останется еще 20${UnicodeEnum.NBSP}дней.`,
      validWithinHundredKm: `Действует в 100+${UnicodeEnum.NBSP}км от места проживания`,
      policyIsValid: `В рамках одного путешествия полис действует не более {{day}}${UnicodeEnum.NBSP}дней`,
      forSchengenCountries: `Для стран Шенгена мы увеличиваем срок действия полиса на 15${UnicodeEnum.NBSP}дней. При этом количество застрахованных дней не изменится.`,
      exampleForSchengenCountries: `Пример: Георгий выбрал пятидневный полис с 1 по 5 августа. Но визовый центр задержал выдачу документов и Георгий прилетел в Париж только 10${UnicodeEnum.NBSP}августа. Страховка будет действовать 5 дней с 10 по 14${UnicodeEnum.NBSP}августа.`,
    },
    errors: {
      requiredStartDate: 'Укажите дату начала действия полиса',
      requiredEndDate: 'Укажите дату окончания действия полиса',
      requiredMessage: 'Поле обязательно для заполнения',
      requiredCountry: 'Укажите одну или несколько стран',
      requiredDuration: 'Укажите длительность',
      endDateMustEarlier: 'Дата окончания поездки должна быть раньше',
      startDateShouldLater: 'Дата начала поездки должна быть позже',
      endDateMustNotEarlier: 'Дата окончания поездки должна быть не раньше',
    },
  },
};
