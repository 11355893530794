import { useRequest, useStores } from '@shared/hooks';
import type { IFLFlatInsuranceProduct } from '@shared/types';
import { Endpoints, PRODUCT_CODE } from '@shared/constants';
import { useEffect } from 'react';

export const useHandleProduct = () => {
  const {
    MainStore: {
      initProductStore: { initState, setInitState },
    },
  } = useStores();

  const {
    isLoading: isLoadingProduct,
    res: resProduct,
    refetch: refetchProduct,
  } = useRequest<IFLFlatInsuranceProduct>(
    'get-insurance-product',
    'post',
    Endpoints.GET_INSURANCE_PRODUCT,
    {
      productCode: PRODUCT_CODE,
    },
    [],
    true
  );

  useEffect(() => {
    if (!initState?.code) {
      refetchProduct();
    }
  }, []);

  useEffect(() => {
    if (!isLoadingProduct && resProduct) {
      setInitState({ ...initState, ...resProduct });
    }
  }, [isLoadingProduct, resProduct]);

  return refetchProduct;
};
