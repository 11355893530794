export const i18nDefaultValues = {
  WantImprovePolicy: {
    headers: {
      improvePolicy: 'Хотите улучшить полис?',
      includedInThePolicy: 'В полис включено',
      basicOptions: '14 базовых опций — в них нуждаются чаще всего',
    },
    labels: {
      travelCostCheck: 'Стоимость поездки (по чекам)',
      sumInsured: 'Страховая сумма',
      showAll: 'Показать все',
    },
    hints: {
      expandOptions:
        '<span>Вы можете расширить <styledLink>базовую защиту</styledLink> дополнительными опциями</span>',
    },
  },
};
