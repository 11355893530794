/* eslint-disable indent */
export const getMessageErrorForEndDate = (error: any) => {
  const type = error?.type || error?.end?.type;
  const message = error?.message || error?.end?.message;

  switch (type) {
    case 'start-date-more-end-date':
    case 'traveling-start-date-more-end-date':
    case 'period-traveling':
    case 'optionality':
    case 'period-policy':
    case 'is-date':
      return message;

    default:
      return '';
  }
};
