import { checkPolicy } from './check-policy';
import { commonNS, commonNamespaces } from './common';
import { smartHeaderNS } from './smart-header';

const smartNamespaces = 'SMART';

export const resources = {
  ru: {
    ...commonNS,
    [smartNamespaces]: {
      ...smartHeaderNS,
      ...checkPolicy,
    },
  },
};

export const namespaces = [...commonNamespaces, smartNamespaces];
