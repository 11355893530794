import { type InsuredAmountConfigurationOptions } from '@pulse-smart-components-kit/insured-amount-configuration';
import { ReactNode } from 'react';
import {
  MockPeriodBadge,
  MockPeriodWrapper,
} from './insurance-period-and-limits-adapter.style';

export const mockInsurancePeriodAndLimitsOptions: InsuredAmountConfigurationOptions =
  {
    subObjects: [
      {
        code: 'CONSTRUCTION',
        name: 'Стены и перекрытия',
        description:
          'Конструктивные элементы: стены, полы, потолки, перегородки, перекрытия для квартиры, а также фундамент, крыша и внешняя отделка для частного дома',
        minLimit: '0',
        maxLimit: '15000000.0',
        step: '100000',
        defaultInsuranceSum: '500000.0',
        imageUrl:
          'https://storage.cloud.croc.ru:443/web-lk-pulse-dev-res/pics-mw/SubObjects/Construction_1.56.png',
        tooltip:
          'Если квартира не газифицирована, выберите минимальную сумму. Если есть газ — рекомендуем выставить в размере от 50 до 100% оценочной стоимости квартиры',
        alternativeName: 'Как выбрать страховую сумму для стен и перекрытий?',
        alternativeDescription:
          'Если квартира не газифицирована, то вероятность полного разрушения стен и перекрытий очень мала. В таком случае мы рекомендуем ограничиться минимальной страховой суммой. Если же в квартире есть газ, то рекомендуется выбрать сумму в размере от 50% до 100% оценочной стоимости квартиры',
      },
      {
        code: 'DECORATION',
        name: 'Внутренняя отделка и инженерные коммуникации',
        description:
          'Двери и окна, отделка стен, потолка и пола, сантехническое и инженерное оборудование, электропроводка и т.п.',
        minLimit: '0',
        maxLimit: '5000000.0',
        step: '100000',
        defaultInsuranceSum: '900000.0',
        imageUrl:
          'https://storage.cloud.croc.ru:443/web-lk-pulse-dev-res/pics-mw/SubObjects/Decoration_1.56.png',
        tooltip:
          'Рекомендуем оценить примерную стоимость вашего ремонта и установить такую же сумму',
      },
      {
        code: 'MOVABLES',
        name: 'Мебель, техника, вещи',
        description:
          'Движимое имущество: мебель, техника, вещи, спортивный инвентарь, предметы интерьера, детские коляски и т.п.',
        minLimit: '0',
        maxLimit: '2000000.0',
        step: '100000',
        defaultInsuranceSum: '800000.0',
        imageUrl:
          'https://storage.cloud.croc.ru:443/web-lk-pulse-dev-res/pics-mw/SubObjects/Movables_1.56.png',
        tooltip:
          'Настройте сумму исходя из оценки вашего имущества — за исключением наличных денег, украшений и ценных бумаг',
        alternativeName:
          'Как выбрать страховую сумму для мебели, техники, вещей?',
        alternativeDescription:
          'Максимальная выплата за каждую пострадавшую вещь составляет 10% от страховой суммы. Вспомните самую дорогую вещь в вашей квартире и умножьте ее цену на 10. Так вы получите страховую сумму от которой и можно отталкиваться в выборе общего покрытия.',
        example:
          '<strong>Например:</strong><br/>если вы застраховали свои вещи, технику и мебель на <strong>2 000 000 ₽</strong>, то максимальная сумма выплаты за каждую пострадавшую вещь составит <strong>200 000 ₽</strong>',
      },
      {
        code: 'Liability_1',
        name: 'Ущерб соседям',
        description:
          'Движимое имущество: мебель, техника, вещи, спортивный инвентарь, предметы интерьера, детские коляски и т.п.',
        minLimit: '0',
        maxLimit: '2000000.0',
        step: '100000',
        defaultInsuranceSum: '500000.0',
        imageUrl:
          'https://storage.cloud.croc.ru:443/web-lk-pulse-dev-res/pics-mw/SubObjects/Movables_1.56.png',
        tooltip:
          'Настройте сумму исходя из оценки вашего имущества — за исключением наличных денег, украшений и ценных бумаг',
        alternativeName: 'Как выбрать страховую сумму для ущерба соседям?',
        alternativeDescription:
          'Оцените имущество соседей так, как если бы это была ваша квартира',
        example:
          '<strong>Обратите внимание:</strong> <br /> чем выше у вас этаж, тем больше риск затопить сразу несколько квартир',
      },
    ],
    totalMinLimit: '500000',
    totalMaxLimit: 27000000,
    scoringValue: '0.9',
  };

export type PeriodValue = 'month' | '3 months' | 'year';

interface MockPeriodOptions {
  value: PeriodValue;
  label: ReactNode;
}

export const mockPeriodOptions: MockPeriodOptions[] = [
  { value: 'month', label: '1 месяц' },
  {
    value: '3 months',
    label: (
      <MockPeriodWrapper>
        3 месяца <MockPeriodBadge>-XX%</MockPeriodBadge>{' '}
      </MockPeriodWrapper>
    ),
  },
  {
    value: 'year',
    label: (
      <MockPeriodWrapper>
        Год <MockPeriodBadge>-XX%</MockPeriodBadge>{' '}
      </MockPeriodWrapper>
    ),
  },
];
