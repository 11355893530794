import { action, makeAutoObservable, observable } from 'mobx';

export class ErrorStore {
  displayGlobalErrorUrl?: string = undefined; // Для сохранения запроса с ошибкой
  errorRetry: boolean = false; // Повторный запрос на получение ответа от бекенда

  constructor() {
    makeAutoObservable(this, {
      displayGlobalErrorUrl: observable,
      errorRetry: observable,
      setDisplayGlobalErrorUrl: action,
      setErrorRetry: action,
    });
  }

  setDisplayGlobalErrorUrl = (e: string | undefined) => {
    this.displayGlobalErrorUrl = e;
  };

  setErrorRetry = (e: boolean) => {
    this.errorRetry = e;
  };
}
