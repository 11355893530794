import type { FC } from 'react';
import type { SmartComponentAdapterProps } from 'smart-components';

import {
  DocumentsListAdapter,
  FooterAdapter,
  HeaderAdapter,
  SubmitAdapter,
  WantImprovePolicyAdapter,
  WhereAndHowLongAdapter,
  WhoAndHowAdapter,
  WhoAndHowPaperworkAdapter,
  WhoIssuesPolicyAdapter,
  B2PAdapter,
  withCommonSmartComponentAdapter,
  InsurancePeriodAndLimitsAdapter,
  InsuranceRisksAdapter,
  InsurancePersonsAdapter,
  CheckDataAdapter,
  StartDateAdapter,
  IFLFLatAdapter,
  CheckPolicyAdapter,
} from '@entities/adapters';
import { MockSmartComponent } from './mock-smart-component';

type TypeImportSmartComponents = {
  [key: string]: FC<SmartComponentAdapterProps<any>>;
};

export const importSmartComponents: TypeImportSmartComponents = {
  WhereAndHowLong: WhereAndHowLongAdapter,
  WhoAndHowPaperwork: WhoAndHowPaperworkAdapter,
  WhoAndHow: WhoAndHowAdapter,
  WantImprovePolicy: WantImprovePolicyAdapter,
  WhoIssuesPolicy: WhoIssuesPolicyAdapter,
  DocumentsList: DocumentsListAdapter,
  B2P: B2PAdapter,
  FlatAddress: IFLFLatAdapter,
  InsurancePersons: InsurancePersonsAdapter,
  InsuranceRisks: InsuranceRisksAdapter,
  InsurancePeriodAndLimits: InsurancePeriodAndLimitsAdapter,
  CheckData: CheckDataAdapter,
  MockSmartComponent: withCommonSmartComponentAdapter(MockSmartComponent),
  StartDate: StartDateAdapter,
  CheckPolicy: CheckPolicyAdapter,
};

type TypeImportCommonComponents = {
  [key: string]: FC<any>;
};

export const importCommonComponents: TypeImportCommonComponents = {
  Header: HeaderAdapter,
  Footer: FooterAdapter,
  Submit: SubmitAdapter,
};
