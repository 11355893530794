// import { useContext, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { StyledAppContent } from './app-content.styles';
import { useAxiosInterceptors, useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';

type AppContentType = {
  children: ReactNode;
};

export const AppContent: FC<AppContentType> = observer(({ children }) => {
  const {
    MainStore: {
      errorStore: { setDisplayGlobalErrorUrl, displayGlobalErrorUrl },
      authStore: { setAuthTokens },
    },
  } = useStores();
  useAxiosInterceptors(
    setAuthTokens,
    setDisplayGlobalErrorUrl,
    displayGlobalErrorUrl
  );

  return <StyledAppContent>{children}</StyledAppContent>;
});
