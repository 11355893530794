import type { InsuranceLimitStart as InsuranceLimitStartResponse } from '@shared/types/insurance-limit-start.types';
import { Endpoints, PRODUCT_CODE } from '@shared/constants';
import { useRequest, useStores } from '@shared/hooks';
import { useEffect } from 'react';

export const useInsuranceLimitStart = () => {
  const {
    MainStore: {
      initProductStore: { initState, setInsuranceLimitStart },
    },
  } = useStores();

  const {
    res: resInsuranceLimitStart,
    refetch: refetchInsuranceLimit,
    isLoading: isLoadingInsuranceLimitStart,
  } = useRequest<InsuranceLimitStartResponse>(
    'get-sport-activity',
    'get',
    `${Endpoints.INSURANSE_LIMIT_START}/${PRODUCT_CODE}/1 `,
    {},
    [],
    true
  );

  useEffect(() => {
    if (!initState?.maxProductLimit) {
      refetchInsuranceLimit();
    }
  }, [initState?.maxProductLimit]);

  useEffect(() => {
    if (resInsuranceLimitStart && !isLoadingInsuranceLimitStart) {
      setInsuranceLimitStart(resInsuranceLimitStart);
    }
  }, [resInsuranceLimitStart, isLoadingInsuranceLimitStart]);

  return {
    isLoadingInsuranceLimitStart,
    resInsuranceLimitStart,
    refetchInsuranceLimit,
  };
};
