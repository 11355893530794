/* eslint-disable indent */
import { memo } from 'react';

import { FooterWrapper } from './footer-smart-component.styles';
import type { FooterSmartComponentProps } from './footer-smart-component.types';
import { ItemPrice } from './footer-smart-component-price';

export enum FooterAnalyticEvent {
  ON_OPEN,
  ON_VIEW_OPTIONS,
}

const FooterSmartComponent = memo(
  ({
    value,
    activeStep,
    hasPricesCalcErr,
    maxSteps,
  }: FooterSmartComponentProps) => {
    return (
      <FooterWrapper data-test="Footer-block">
        <ItemPrice
          price={value.price}
          errorState={hasPricesCalcErr}
          activeStep={activeStep}
          maxSteps={maxSteps}
        />
      </FooterWrapper>
    );
  }
);

FooterSmartComponent.displayName = 'FooterSmartComponent';
export default FooterSmartComponent;
