import { styled, media } from '@pulse-web-ui/theme';

export const StyledLabelWrapper = styled.div`
  margin-bottom: 16px;

  ${media.desktop} {
    margin-bottom: 24px;
  }
`;

export const StyledWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
