export const i18nDefaultValues = {
  FooterSmartComponent: {
    headers: {
      includedInThePolicy: 'В полис включено',
      basicOptions: '14 базовых опций — в них нуждаются чаще всего',
      policyPrice: 'Цена полиса',
    },
    labels: {
      notSelected: 'Не выбрано',
      policyPrice: 'Цена полиса',
      countryAndRegion: 'Страна или регион',
      included: 'Включено',
      insuranceSum: 'Сумма покрытия',
      dates: 'Даты',
    },
    errors: {
      notSelected: 'Не выбраны',
    },
    declensions: {
      option: 'опция',
      option2: 'опции',
      option3: 'опций',
      service: 'услуга',
      service2: 'услуги',
      service3: 'услуг',
    },
  },
};
