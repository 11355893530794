import { Suspense, forwardRef, useEffect } from 'react';
import type { Ref } from 'react';
import { observer } from 'mobx-react-lite';
import type { InsuredAmountConfigurationValues } from '@pulse-smart-components-kit/insured-amount-configuration';
import { WidgetContainer } from '@pulse-web-ui/containers';
import { SegmentGroup } from '@pulse-web-ui/segment-group';

import { useStores } from '@shared/hooks';
import { FallbackSkeleton } from '@shared/components';
import { SmartInsuranceAmountConfiguration } from '@entities/import-smart-components/smart-insurance-amount-configuration';
import type { SmartComponentAdapterProps } from 'smart-components';
import {
  mockInsurancePeriodAndLimitsOptions,
  type PeriodValue,
  mockPeriodOptions,
} from './mock-preiod-and-limits';
import {
  StyledWrapper,
  StyledPeriodWrapper,
} from './insurance-period-and-limits-adapter.style';
import { prepareConfigurationOptions } from './utils';
import { useTranslation } from 'react-i18next';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';

export const InsurancePeriodAndLimitsAdapter = observer(
  forwardRef<
    HTMLDivElement,
    SmartComponentAdapterProps<InsuredAmountConfigurationValues>
  >(({ isSubmitting, onChange }, forwardRef: Ref<HTMLDivElement>) => {
    const {
      MainStore: {
        authStore: { disableForm },
      },
    } = useStores();
    const { t } = useTranslation();

    useEffect(() => {
      onChange({
        limits: {},
        isValid: true,
      });
    }, []);

    //TODO: До реализации бизнес логики
    const handleLimitsChange = (value: InsuredAmountConfigurationValues) => {
      console.log(value);
    };

    //TODO: До реализации бизнес логики
    const handlePeriodChange = (value: PeriodValue) => {
      console.log(value);
    };

    return (
      <WidgetContainer>
        <Suspense fallback={<FallbackSkeleton height={450} />}>
          <StyledWrapper>
            <StyledPeriodWrapper>
              <HeaderWithSubText
                title={t('INSURANCE_PERIOD_AND_LIMITS:label.periodName')}
              />
              <SegmentGroup
                name="period"
                options={mockPeriodOptions}
                onChange={handlePeriodChange}
              />
            </StyledPeriodWrapper>
            <SmartInsuranceAmountConfiguration
              disabled={disableForm}
              isSubmitting={isSubmitting}
              onChange={handleLimitsChange}
              ref={forwardRef}
              options={{
                options: prepareConfigurationOptions(
                  mockInsurancePeriodAndLimitsOptions
                ),
                subTitle: t('INSURANCE_PERIOD_AND_LIMITS:label.subTitle'),
                title: t('INSURANCE_PERIOD_AND_LIMITS:label.title'),
              }}
            />
          </StyledWrapper>
        </Suspense>
      </WidgetContainer>
    );
  })
);

InsurancePeriodAndLimitsAdapter.displayName = 'InsurancePeriodAndLimitsAdapter';
