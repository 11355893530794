import { useRequest, useStores } from '@shared/hooks';
import { Endpoints, QUERY_KEYS } from '@shared/constants';
import { useEffect, useMemo } from 'react';

import type {
  UpdateProfileResponse,
  UpdateProfileRequest,
} from './use-update-profile.types';
import { getFormattedDate } from '@shared/utils';

export const useUpdateProfile = () => {
  const {
    MainStore: {
      errorStore: { setErrorRetry },
      authStore: {
        handleProfile,
        profile,
        accessToken,
        setDisableForm,
        handleUpdateDisableProfileState,
      },
      productStore: { formState },
    },
  } = useStores();

  const requestData: UpdateProfileRequest = useMemo(() => {
    const changedBirthDate = formState?.WhoIssuesPolicy?.birthDate
      ? getFormattedDate(formState?.WhoIssuesPolicy?.birthDate)
      : '';
    const email =
      profile?.profile?.email !== formState?.WhoIssuesPolicy?.email
        ? formState?.WhoIssuesPolicy?.email
        : undefined;
    const birthDate =
      profile?.profile?.birthDate !== changedBirthDate
        ? changedBirthDate
        : undefined;
    const firstName =
      profile?.profile?.firstName !== formState?.WhoIssuesPolicy?.firstName
        ? formState?.WhoIssuesPolicy?.firstName
        : undefined;
    const middleName =
      profile?.profile?.middleName !== formState?.WhoIssuesPolicy?.secondName
        ? formState?.WhoIssuesPolicy?.secondName
        : undefined;
    const lastName =
      profile?.profile?.lastName !== formState?.WhoIssuesPolicy?.lastName
        ? formState?.WhoIssuesPolicy?.lastName
        : undefined;

    return {
      clientChange: {
        email,
        birthDate,
        firstName,
        middleName,
        lastName,
      },
    };
  }, [formState.WhoIssuesPolicy, profile?.profile]);

  const { isLoading, res, refetch } = useRequest<UpdateProfileResponse>(
    QUERY_KEYS.updateProfile,
    'post',
    Endpoints.UPDATE_PROFILE,
    requestData,
    [profile, formState],
    true,
    accessToken
  );

  useEffect(() => {
    if (!isLoading && res) {
      const profileValues = {
        name: !!res?.profile?.firstName,
        surname: !!res?.profile?.lastName,
        secondName: res?.lockedFields?.middleName || !!res?.profile?.middleName,
        birthDate: !!res?.profile?.birthDate,
      };

      setDisableForm(false);
      setErrorRetry(false);
      handleProfile(res);
      handleUpdateDisableProfileState(profileValues);
    }
  }, [isLoading, res]);

  return refetch;
};
