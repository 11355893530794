import i18n from '@app/i18n/config';
import { boolean, object, array, string } from 'yup';

import { i18nDefaultValues } from '../i18n';

export const getDocumentsListSchema = () =>
  object().shape({
    documentsList: array(),
    token: string(),
    acceptRequirements: boolean()
      .nonNullable()
      .test({
        name: 'acceptRequirements',
        message: i18n.t(
          'SMART:DocumentsList.errors.acceptRequirements',
          i18nDefaultValues.DocumentsList.errors.acceptRequirements
        ),
        test: (value) => value === true,
      })
      .required(
        i18n.t(
          'SMART:DocumentsList.errors.acceptRequirements',
          i18nDefaultValues.DocumentsList.errors.acceptRequirements
        )
      ),
    isValid: boolean(),
  });
