import { RisksOptionsType } from '@shared/types';

export function arrayOrder(
  array: RisksOptionsType[],
  order: string[],
  key: keyof RisksOptionsType
) {
  array.sort(function (a: RisksOptionsType, b: RisksOptionsType) {
    const A = String(a[key]),
      B = String(b[key]);

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  return array;
}
