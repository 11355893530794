import { Trans, useTranslation } from 'react-i18next';
import { i18nDefaultValues } from './i18n';
import { TERMS_POLICY } from './constants';

export const getMock = () => {
  const { t } = useTranslation();

  return {
    onDownload: (n: number) => {
      void n;
    },
    policyIncludedConfig: {
      coverageSum: 100000,
      subTitle: 'х покрытия и x риска',
      coverageSumTitle: 'Общая страховая сумма',
      productItems: [
        { title: 'Пожар', value: 'до 100 000 P' },
        { title: 'Затопление', value: 'до 100 000 P' },
        { title: 'Кража', value: 'до 100 000 P' },
      ],
      listTitle: 'Страховые риски',
      listItems: ['Пожар', 'Затопление', 'Кража'],
    },
    insuranceEntitiesConfig: {
      items: [
        {
          title: 'Страхователь',
          key: 1,
          description: (
            <>
              Константинов Николай Иванович
              <br />
              kostya@mail.ru
            </>
          ),
        },
        {
          title: 'Адрес квартиры',
          key: 2,
          description: (
            <>Воронежская область, г. Нововоронеж, ул. Весенняя, д. 42, кв. 3</>
          ),
        },
        {
          title: 'Дата начала действия',
          key: 3,
          description: <>15.01.2024</>,
        },
      ],
    },
    documentsList: [
      { clientDocument: '', clientDocumentType: 'Memo' },
      { clientDocument: '', clientDocumentType: 'Rules' },
      { clientDocument: '', clientDocumentType: 'Contract' },
      {
        clientDocument: '',
        clientDocumentType: 'KeyInfoDocument',
      },
    ],
    acceptTermsConfig: {
      title: (
        <Trans
          components={{
            AcceptTermsLink: <a />,
          }}
          values={{ href: TERMS_POLICY }}
        >
          {t('SMART:CheckPolicy.labels.acceptTermsPolicy', {
            defaultValue:
              i18nDefaultValues.CheckPolicy.labels.acceptTermsPolicy,
          })}
        </Trans>
      ),
    },
  };
};
