/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  HeaderStep,
  HeaderBaseIconKey,
} from '@pulse-smart-components-kit/header-base';

export enum IFLFlatStep {
  address = 'address',
  calculation = 'calculation',
  options = 'options',
  formalization = 'formalization',
  payment = 'payment',
}
export type IFLFlatProductConfig = ProductConfig<FlatGlobalVariables>;

export type FlatGlobalVariables = {
  otpStep: number;
  isShowBackButton: boolean; // Флаг отображения кнопки "Назад"
};

export type ProductConfig<T> = {
  name: string;
  header: ConfigSmartComponent[];
  footer: ConfigSmartComponent[];
  submit: ConfigSmartComponent[];
  flow: ProductStep[];
  globalVariables: T;
};

export type ProductStep = {
  components: ConfigSmartComponent[];
  icon: HeaderBaseIconKey;
  name: string;
  additionalVariables?: {
    [key: string]: any;
  };
};

export type ConfigSmartComponent = {
  name: string;
  id: string;
  additionalVariables?: {
    value?: any;
    [key: string]: any;
  };
};

export type FlowConfig<T> = T & {
  headerConfig: HeaderStep[];
  stepConfig: StepConfig[];
  maxSteps: number;
};

export type StepConfig = {
  step: number;
  value: {
    [key: string]: any;
  };
};
