import { DEFAULT_TRAVELER } from '../constants';
import type { WhoAndHowPaperworkValues } from '../who-and-how-paperwork.types';

export const getDefaultValues = (
  value: WhoAndHowPaperworkValues | undefined,
  agesLength: number
) => {
  const travelersLength = value?.travelers?.length || 0;

  if (travelersLength < agesLength) {
    const newTravelers = Array(agesLength - travelersLength).fill(
      DEFAULT_TRAVELER
    );

    return {
      travelers: [...(value?.travelers || []), ...newTravelers],
    };
  }

  return value;
};
