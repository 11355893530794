import { SelectorCard } from '@pulse-web-ui/selector-card';
import { FC, useState } from 'react';
import { Content } from './content';
import type { RisksOptionsType } from '@shared/types';
import { RadioCardProps } from '../want-improve-policy.types';
import { Currency } from '@pulse-web-ui/range-input';
import { WantImprovePolicyAnalyticEvent } from '..';

export const RadioCard: FC<RadioCardProps> = ({
  value,
  currency,
  onChange,
  index,
  onAnalyticEventSend,
}) => {
  const [hasFocus, setHasFocus] = useState(false);

  const getContent = (item: RisksOptionsType) => {
    if (!item.isRadio && !item.isSlider) {
      return;
    }

    const handleChange = (val: number, id: string) => {
      if (id === value.code) {
        onChange({
          ...value,
          sum: val,
        });
      }

      return value;
    };

    if (item.checked) {
      return (
        <Content
          item={{ ...item, name: item.code }}
          onChange={handleChange}
          currency={currency as Currency}
          hasFocus={hasFocus}
        />
      );
    }
  };

  const handleClick = (id: string) => () => {
    if (value.code === id) {
      // Дефолтное значение при клике
      if (value.sum === 0) {
        value.sum = value.coverageSum[0];
      }
      onChange({
        ...value,
        checked: !value.checked,
      });
      if (!value.checked) {
        onAnalyticEventSend &&
          onAnalyticEventSend(WantImprovePolicyAnalyticEvent.ON_CARD_CLICK);
      }

      setHasFocus(true);
    }
  };

  return (
    <div
      data-test={
        index || index === 0 ? `Option-improve-policy-${index + 1}` : ''
      }
    >
      <SelectorCard
        name={value.name}
        key={`${value.code}`}
        id={value.code}
        label={value.name}
        checked={value.checked}
        description={value.description}
        stopContentPropagation={value.checked}
        content={getContent(value)}
        onClick={handleClick(value.code)}
        mobileFullWidth
      />
    </div>
  );
};
