import { styled, media } from '@pulse-web-ui/theme';

export const TravelingStyle = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  flex-basis: 50%;
  ${media.desktop} {
    justify-content: flex-start;
  }
`;
