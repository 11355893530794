import { type FC, Suspense, forwardRef, type Ref } from 'react';
import { observer } from 'mobx-react-lite';

import { useStores } from '@shared/hooks';
import { MockSmartComponent } from '@entities/import-smart-components/mock-smart-component';
import type { SmartComponentAdapterProps } from 'smart-components';

export const CoverageSumAdapter: FC<SmartComponentAdapterProps<unknown>> =
  observer(
    forwardRef(
      (
        { value, onChange, isSubmitting, fieldState },
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const {
          MainStore: {
            authStore: { disableForm },
          },
        } = useStores();

        // TODO: Удалить когда будет реальный смарт-компонент
        const options = {
          title: 'Выберите сумму покрытия',
        };

        return (
          <Suspense>
            <MockSmartComponent
              ref={forwardRef}
              value={value}
              onChange={onChange}
              isSubmitting={isSubmitting}
              options={options}
              disabled={disableForm}
              fieldState={fieldState}
            />
          </Suspense>
        );
      }
    )
  );

CoverageSumAdapter.displayName = 'CoverageSumAdapter';
