import { B2P } from '@entities/import-smart-components/b2p';
import { type FC, type Ref, Suspense, forwardRef } from 'react';
import { FallbackSkeleton } from '@shared/components';
import type { SmartComponentAdapterProps } from 'smart-components';
import type { B2PValues } from 'smart-components/b2p/b2p.type';
import { observer } from 'mobx-react-lite';
import { useStores } from '@shared/hooks';
import type { AnalyticEventsMap } from '@shared/types';
import { B2PAnalyticEvent } from 'smart-components/b2p';
import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';

const analyticEventsMap: AnalyticEventsMap<B2PAnalyticEvent> = {
  [B2PAnalyticEvent.IS_STEP_PAY]: {
    name: analyticEvents.travelToStepPay,
  },
};

export const B2PAdapter: FC<SmartComponentAdapterProps<B2PValues>> = observer(
  forwardRef(
    (
      { value, onChange, isSubmitting, fieldState },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          authStore: { disableForm },
        },
      } = useStores();

      const handleAnalyticEventSend =
        useHandleSmartComponentsAnalyticEvents<B2PAnalyticEvent>(
          analyticEventsMap
        );

      return (
        <Suspense fallback={<FallbackSkeleton height={0} transparent />}>
          <B2P
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            fieldState={fieldState}
            disabled={disableForm}
            options={null}
            onAnalyticEventSend={handleAnalyticEventSend}
          />
        </Suspense>
      );
    }
  )
);

B2PAdapter.displayName = 'B2PAdapter';
