import { useEffect } from 'react';
import { useRequest, useStores } from '@shared/hooks';
import { Endpoints } from '@shared/constants';
import type { ContractDataType, InitOrderResponse } from '@shared/types';
import type { FormBuilderUpdateHandler } from '@features/form-builder';
import { GetSubmitOrderResponse, getInitOrderData } from '@shared/queries';

export const useInitOrder = (
  updateFormValue: FormBuilderUpdateHandler,
  resSubmitOrder: GetSubmitOrderResponse | undefined
) => {
  const {
    MainStore: {
      applicationStore: { paymenStep, retryPayment },
      initProductStore: { setInitOrder },
      errorStore: { setErrorRetry },
      authStore: { profile, accessToken, setDisableForm },
    },
  } = useStores();

  const contractData: ContractDataType = getInitOrderData(
    resSubmitOrder,
    profile
  );
  const {
    isLoading,
    res: resInit,
    refetch: refetchInit,
  } = useRequest<InitOrderResponse>(
    'initOrder',
    'post',
    Endpoints.INIT_ORDER,
    contractData,
    [contractData, accessToken],
    true,
    accessToken
  );

  useEffect(() => {
    if (!isLoading && resInit) {
      updateFormValue('B2P', {
        initOrder: resInit,
        showInitOrder: true,
        isValid: true,
      });
      setErrorRetry(false);
      setInitOrder(resInit);
      setDisableForm(true);
    }
  }, [isLoading, resInit]);

  useEffect(() => {
    if (paymenStep && resSubmitOrder && !retryPayment) {
      setErrorRetry(false);
      setDisableForm(true);
      refetchInit();
    }
  }, [paymenStep, resSubmitOrder]);

  return { refetchInit, resInit };
};
