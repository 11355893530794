export const analyticEvents = {
  webStart: 'Purchase_Web_Start',
  travelStart: 'Purchase_Travel_Start',
  travelCountrySelected: 'Purchase_Travel_Country_Selected',
  travelCantSell: 'Purchase_Travel_CantSell',
  travelDatesSelected: 'Purchase_Travel_Dates_Selected',
  travelTravellersSelected: 'Purchase_Travel_Travellers_Selected',
  travelSumSelected: 'Purchase_Travel_Sum_Selected',
  travelSport: 'Purchase_Travel_Sport',
  travelSummary: 'Purchase_Travel_Summary',
  travelSportSelected: 'Purchase_Travel_Sport_Selected',
  travelViewOptionsFooter: 'Purchase_Travel_ViewOptions_Footer',
  travelViewOptionsOptions: 'Purchase_Travel_ViewOptions_Options',
  travelOptionAdded: 'Purchase_Travel_Option_Added',
  travelToStepTravelData: 'Purchase_Travel_To_Step_TravelData',
  travelToStepAddOptions: 'Purchase_Travel_To_Step_AddOptions',
  travelToStepData: 'Purchase_Travel_To_Step_Data',
  travelToStepPay: 'Purchase_Travel_To_Step_Pay',
  travelToStepDocs: 'Purchase_Travel_To_Step_Docs',
  travelOwnerDataEntered: 'Purchase_Travel_Owner_Data_Entered',
  travelOwnerPhoneEntered: 'Purchase_Travel_Owner_Phone_Entered',
  travelOwnerMailEntered: 'Purchase_Travel_Owner_Mail_Entered',
  travelerTravellerDataEntered: 'Purchase_Travel_Traveller_Data_Entered',
  travelerTravellerAdded: 'Purchase_Travel_Traveller_Added',
  travelerTravellerDeleted: 'Purchase_Travel_Traveller_Deleted',
  travelerSignupStart: 'Purchase_Travel_Signup_Start',
  travelRegistrationStart: 'Purchase_Travel_Registration_Start',
  travelSignupSuccess: 'Purchase_Travel_Signup_Success',
  travelerEnterCodeSuccess: 'Purchase_Travel_EnterCode_Success',
  travelRegistrationSuccess: 'Purchase_Travel_Registration_Success',
  travelPaymentSuccess: 'Purchase_Travel_Payment_Success',
  travelPaymentDecline: 'Purchase_Travel_Payment_Decline',
};
