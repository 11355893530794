import { useStores } from '@shared/hooks';
import type { SmartComponentStoreKey } from '@entities/stores';
import {
  type FC,
  memo,
  Suspense,
  type LazyExoticComponent,
  type Ref,
  forwardRef,
} from 'react';
import type {
  SmartComponentAdapterProps,
  SmartComponentProps,
} from 'smart-components';
import { observer } from 'mobx-react-lite';

type LazyComponentType = LazyExoticComponent<
  React.FC<SmartComponentProps<any, any>>
>;
type CommonAdapterProps = SmartComponentAdapterProps<any> & {
  component: LazyComponentType;
};

//Базовый адаптер для большинства компонентов.
const CommonSmartComponentAdapter: FC<CommonAdapterProps> = observer(
  forwardRef(
    (
      { value, onChange, isSubmitting, name, component: Component, fieldState },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          smartComponentAdditionalOptionsStore: { additionalOptions },
          authStore: { disableForm },
        },
      } = useStores();

      const additionalOptionsForComponent =
        additionalOptions[name as SmartComponentStoreKey];

      return (
        <Suspense>
          <Component
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={additionalOptionsForComponent}
            disabled={disableForm}
            fieldState={fieldState}
          />
        </Suspense>
      );
    }
  )
);

CommonSmartComponentAdapter.displayName = 'CommonSmartComponentAdapter';

//HOC для создания компонента базового адаптора на основе lazy компонента.
export const withCommonSmartComponentAdapter = (
  component: LazyComponentType
) => {
  const ComponentWithProps: FC<SmartComponentAdapterProps<any>> = memo(
    (props: SmartComponentAdapterProps<any>) => {
      return <CommonSmartComponentAdapter component={component} {...props} />;
    }
  );

  ComponentWithProps.displayName = 'WrappedLazyComponent';

  return ComponentWithProps;
};
