import { Suspense, forwardRef, useEffect } from 'react';
import type { Ref, FC } from 'react';
import { observer } from 'mobx-react-lite';

import { useStores } from '@shared/hooks';
import type { SmartComponentAdapterProps } from 'smart-components';
import { formInsuranceRisksSchema } from './schema';
import { RISKS_MOCK } from './mock-risks';
import { InsuranceRisksValues } from '@pulse-smart-components-kit/insurance-risks';
import { SmartInsuranceRisks } from '@entities/import-smart-components/smart-insurance-risks';
import { FallbackSkeleton } from '@shared/components';

export const InsuranceRisksAdapter: FC<
  SmartComponentAdapterProps<InsuranceRisksValues>
> = observer(
  forwardRef(
    (
      { isSubmitting, fieldState, value, onChange },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          authStore: { disableForm },
        },
      } = useStores();

      //TODO: До реализации бизнес логики
      useEffect(() => {
        onChange({ risks: RISKS_MOCK });
      }, []);

      return (
        <Suspense fallback={<FallbackSkeleton height={460} />}>
          <SmartInsuranceRisks
            onChange={onChange}
            value={value}
            ref={forwardRef}
            disabled={disableForm}
            fieldState={fieldState}
            formSchema={formInsuranceRisksSchema}
            isSubmitting={isSubmitting}
            onAnalyticEventSend={{}}
            formContext={{}}
            options={{}}
          />
        </Suspense>
      );
    }
  )
);

InsuranceRisksAdapter.displayName = 'InsuranceRisksAdapter';
