import { getFormattedDate, scrollToIframe } from '@shared/utils';
import { useStores } from '@shared/hooks';
import { useEffect } from 'react';
import { UpdateProfileResponse } from '@shared/queries/application/update-profile/use-update-profile.types';
import { FieldValues } from 'react-hook-form';

export const useHandleNextStep = (updateProfileRefetch: () => Promise<any>) => {
  const {
    MainStore: {
      applicationStore: {
        activeStep,
        setActiveStep,
        wantNextStep,
        setWantNextStep,
        setPaymenStep,
        flowConfig: { otpStep, maxSteps },
      },
      authStore: { setUseOtp, profile, accessToken, setDisableForm },
      productStore: { formState, formString },
    },
  } = useStores();

  useEffect(() => {
    if (wantNextStep && activeStep < (maxSteps || 0)) {
      scrollToIframe();
      if (
        activeStep === otpStep &&
        wantNextStep &&
        shouldUpdateProfile(formState?.WhoIssuesPolicy, profile?.profile)
      ) {
        setDisableForm(true);
        updateProfileRefetch().then((res) => {
          setDisableForm(false);
          if (res?.data) {
            setActiveStep(activeStep + 1);
          }
        });
      } else {
        setUseOtp(false);
        setActiveStep(activeStep + 1);
      }
    }
    if (wantNextStep && activeStep === maxSteps) {
      setPaymenStep(true);
    }
    setWantNextStep(false);
  }, [wantNextStep, activeStep, accessToken, profile, formState, formString]);
};

const shouldUpdateProfile = (
  WhoIssuesPolicy: FieldValues | undefined,
  profile: UpdateProfileResponse['profile'] | undefined
): boolean => {
  if (!WhoIssuesPolicy || !profile) return false;

  if (compareChanges(WhoIssuesPolicy.email, profile.email)) return true;
  if (
    compareChanges(
      getFormattedDate(WhoIssuesPolicy.birthDate),
      profile.birthDate
    )
  )
    return true;
  if (compareChanges(WhoIssuesPolicy.name, profile.firstName)) return true;
  if (compareChanges(WhoIssuesPolicy.secondName, profile.middleName))
    return true;
  if (compareChanges(WhoIssuesPolicy.surname, profile.lastName)) return true;

  return false;
};

const compareChanges = (
  fieldStore: string | undefined,
  fieldService: string | undefined
): boolean =>
  !!fieldStore && String(fieldStore).localeCompare(String(fieldService)) !== 0;
