import { useState, useEffect, useMemo } from 'react';
import { useStores } from '@shared/hooks';
import type { FooterSmartComponentValue } from 'smart-components';

const defaultFooterValue: FooterSmartComponentValue = {
  price: { premiumAndDelta: '', promoCode: '' },
  countries: [],
  options: 0,
  insuranceSumm: '0',
  insuranceSummCurrency: 'USD',
  dates: { startDate: undefined, endDate: undefined },
};

export const useUpdateFooter = (): FooterSmartComponentValue => {
  const {
    MainStore: {
      productStore: {
        fullRisksOptionsLength,
        countries,
        travelDates,
        insuranceSumm,
        currency,
        price,
      },
    },
  } = useStores();

  const [value, setValue] =
    useState<FooterSmartComponentValue>(defaultFooterValue);

  const countriesNames = useMemo(
    () => countries?.map(({ label }) => label) || [],
    [countries]
  );

  useEffect(() => {
    setValue((prev) => ({
      ...prev,
      price,
      countries: countriesNames,
      options: fullRisksOptionsLength,
      insuranceSumm: insuranceSumm || '0',
      insuranceSummCurrency: currency,
      dates: {
        ...prev.dates,
        startDate: travelDates?.start,
        endDate: travelDates?.end,
      },
    }));
  }, [
    price,
    insuranceSumm,
    currency,
    fullRisksOptionsLength,
    countriesNames,
    travelDates?.start,
    travelDates?.end,
  ]);

  return value;
};
