/* eslint-disable indent */
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Datepicker, ReactDatePicker } from '@pulse-web-ui/datepicker';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Select } from '@pulse-web-ui/select';
import { startOfDay } from 'date-fns';

import { CalendarItem, CalendarStyle } from './calendar.style';

import type { FC } from 'react';
import type { Props } from './calendar.type';

import { convertDateByTimeZoneStrict, getNumberOfDays } from '@shared/utils';
import {
  getMessageErrorForEndDate,
  getMessageErrorForStartDate,
} from './utils';

import { i18nDefaultValues } from '../../i18n';
import { WhereAndHowLongAnalyticEvent } from '../../index';

export const Calendar: FC<Props> = ({
  value,
  onChange,
  error,
  policy,
  traveling,
  startDate,
  endDate,
  insurancePeriodAnnual,
  effectiveSinceFranchise,
  onAnalyticEventSend,
}) => {
  const { t } = useTranslation();
  const [isOpenEndDateCalendar, setIsOpenEndDateCalendar] = useState(false);
  const endDateCalendarRef = useRef<
    ReactDatePicker & { setOpen: (open: boolean) => void }
  >(null);

  const toggleOpenEndDateCalendar = () => {
    setIsOpenEndDateCalendar((prev) => !prev);
  };

  const handleSendDatesAnalytics = (selectedDate?: Date | null) => {
    if (selectedDate) {
      onAnalyticEventSend &&
        onAnalyticEventSend(WhereAndHowLongAnalyticEvent.ON_DATES_SELECT);
    }
  };

  const handleStartDate = (date: Date) => {
    let end = value?.end;

    if (policy) {
      const endDateForPolicy = new Date(date);
      endDateForPolicy.setFullYear(endDateForPolicy.getFullYear() + 1);
      endDateForPolicy.setDate(endDateForPolicy.getDate() - 1);
      end = endDateForPolicy;
    }

    onChange({
      ...value,
      start: date,
      end,
    });

    if (endDateCalendarRef?.current?.setOpen && !end) {
      endDateCalendarRef.current.setOpen(true);
      endDateCalendarRef.current.setFocus();
    }

    handleSendDatesAnalytics(end);
  };

  const handleDuration = (duration: string) => {
    onChange({
      ...value,
      duration: duration,
    });
    handleSendDatesAnalytics(value?.start);
  };

  const minDateTravel = useMemo(() => {
    const minDate = convertDateByTimeZoneStrict(new Date(startDate));

    if (traveling) {
      const countDays = getNumberOfDays(effectiveSinceFranchise);
      if (countDays) {
        const newDate = startOfDay(convertDateByTimeZoneStrict(new Date()));
        newDate.setDate(newDate.getDate() + countDays);
        return newDate;
      }

      return minDate;
    }

    return minDate;
  }, [effectiveSinceFranchise, traveling, startDate]);

  const handleEndDate = (date: Date) => {
    onChange({
      ...value,
      end: date,
      ...(!value?.start ? { start: minDateTravel } : {}),
    });
    handleSendDatesAnalytics(minDateTravel);
  };

  const minDateForEndTravel = useMemo(() => {
    const minDate = new Date(value?.start || startDate);

    if (minDate.getTime() < minDateTravel.getTime())
      return new Date(minDateTravel.getTime());
    return minDate;
  }, [value?.start, startDate, minDateTravel]);

  const options = useMemo(
    () =>
      insurancePeriodAnnual.map((period) => ({
        value: String(period),
        label: String(period),
      })),
    [insurancePeriodAnnual]
  );

  return (
    <CalendarStyle>
      <CalendarItem>
        <HelperText
          noMargin
          status={error ? 'error' : 'default'}
          message={getMessageErrorForStartDate(error)}
        >
          <div data-test={policy ? 'Year-policy-begin-date' : 'Begin-date'}>
            <Datepicker
              disabledKeyboardNavigation
              minDate={minDateTravel}
              maxDate={new Date(endDate)}
              selected={value?.start}
              onChange={handleStartDate}
              label={
                policy
                  ? t('SMART:WhereAndHowLong.labels.startPolicy', {
                      defaultValue:
                        i18nDefaultValues.WhereAndHowLong.labels.startPolicy,
                    })
                  : t('SMART:WhereAndHowLong.labels.startTravel', {
                      defaultValue:
                        i18nDefaultValues.WhereAndHowLong.labels.startTravel,
                    })
              }
              error={!!getMessageErrorForStartDate(error)}
            />
          </div>
        </HelperText>
      </CalendarItem>
      {policy ? (
        <CalendarItem>
          <HelperText
            noMargin
            status={error ? 'error' : 'default'}
            message={error?.duration?.message}
          >
            <div data-test="Year-policy-day-count">
              <Select
                onChange={handleDuration}
                value={value?.duration || ''}
                options={options}
                label={t('SMART:WhereAndHowLong.labels.dateTravel', {
                  defaultValue:
                    i18nDefaultValues.WhereAndHowLong.labels.dateTravel,
                })}
                status={error?.duration?.message ? 'error' : undefined}
                placeholder={t(
                  'SMART:WhereAndHowLong.placeholders.dateTravel',
                  {
                    defaultValue:
                      i18nDefaultValues.WhereAndHowLong.placeholders.dateTravel,
                  }
                )}
              />
            </div>
          </HelperText>
        </CalendarItem>
      ) : (
        <CalendarItem>
          <HelperText
            noMargin
            status={error ? 'error' : 'default'}
            message={getMessageErrorForEndDate(error)}
          >
            <div data-test="End-date">
              <Datepicker
                onCalendarOpen={toggleOpenEndDateCalendar}
                onCalendarClose={toggleOpenEndDateCalendar}
                disabledKeyboardNavigation
                ref={endDateCalendarRef}
                minDate={minDateForEndTravel}
                onChange={handleEndDate}
                selected={value?.end}
                label={t('SMART:WhereAndHowLong.labels.finishTravel', {
                  defaultValue:
                    i18nDefaultValues.WhereAndHowLong.labels.finishTravel,
                })}
                maxDate={new Date(endDate)}
                error={
                  !isOpenEndDateCalendar && !!getMessageErrorForEndDate(error)
                }
              />
            </div>
          </HelperText>
        </CalendarItem>
      )}
    </CalendarStyle>
  );
};
