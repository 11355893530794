/* eslint-disable indent */
import { RISK_VALUE } from '@shared/constants';
import { SHORTED_RISKS_OPTIONS } from '@shared/constants/shorted-risks-options';
import { arrayOrder } from '@shared/utils';
import { getSlider } from './model/utils/get-slider';
import { RisksOptionEnum } from '@shared/types';
import type { Option, RisksType, RisksOptionsType } from '@shared/types';
import type { RisksResponse } from '@shared/queries';

export const prepareRisksOptionsList = (
  resRisks: RisksResponse,
  updateFormValue: (fieldName: string, value: any) => void,
  currentFullOptionsRisks: RisksType[]
) => {
  const includedShortRisks = resRisks.risks?.filter(
    (item: RisksType) => !item.binding
  );

  // Формирование массива с опциями
  const options: RisksOptionsType[] =
    resRisks?.risks
      ?.find((item: RisksType) => item.code === RISK_VALUE)
      ?.options?.filter((item: Option) => !item.binding)
      ?.map((item: Option) => {
        return {
          ...item,
          checked: false,
          type: RisksOptionEnum.option,
          optionSum: item.coverageSum,
        };
      }) || [];

  // Формирование массива с рисками
  const shortRisk: RisksOptionsType[] = includedShortRisks?.map(
    (item: RisksType) => {
      return {
        ...item,
        checked: false,
        items: item.coverageSum?.map((sum: number) => ({
          label: String(sum),
          id: String(sum),
          value: String(sum),
        })),
        isRadio: item.coverageSumByClient && item.discreteCoverage,
        isSlider: item.coverageSumByClient && !item.discreteCoverage,
        slider: getSlider(item),
        sum: item.coverageSumByClient && !item.discreteCoverage ? 1 : 0,
        type: RisksOptionEnum.risk,
      };
    }
  );

  // Опции и риски необходимые для короткого списка;
  const shortItems: RisksOptionsType[] = [...shortRisk, ...options].filter(
    (item) => SHORTED_RISKS_OPTIONS.includes(item.code)
  );

  // Cортировка опций и рисков для короткого списка
  const sortedShortItems = arrayOrder(
    shortItems,
    SHORTED_RISKS_OPTIONS,
    'code'
  );

  const longItems: RisksOptionsType[] = [...options, ...shortRisk].filter(
    (item) => !SHORTED_RISKS_OPTIONS.includes(item.code)
  );

  const mergedArrays = [...sortedShortItems, ...longItems];
  const result =
    currentFullOptionsRisks?.length === mergedArrays.length
      ? mergedArrays.map((item, index) => {
          return {
            ...item,
            sum: currentFullOptionsRisks[index].sum,
            checked: currentFullOptionsRisks[index].checked,
          };
        })
      : mergedArrays;

  updateFormValue('WantImprovePolicy', {
    fullOptionsRisks: result,
  });
};
