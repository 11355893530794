/* eslint-disable indent */
import { StoreTraveler, Traveler } from '@shared/types';
import { differenceInYears } from 'date-fns';
import { AgeSelectItem, DEFAULT_TRAVELER } from 'smart-components';
import { v4 as uuid } from 'uuid';

export const getTravelerFormStoreTraveler = (
  traveler: StoreTraveler
): Traveler => ({
  birthday: traveler.birthday,
  name: traveler.name,
  surname: traveler.surname,
  id: traveler.id,
});

export const getAgeFormStoreTraveler = (
  traveler: StoreTraveler
): AgeSelectItem => ({
  value: traveler.age,
  label: traveler.id,
});

const deleteTravelersByAges = (
  travelers: StoreTraveler[],
  ages: AgeSelectItem[],
  onClearMyselfAge: VoidFn
) => {
  let myselfDeleted = false;
  const travelersFiltered =
    travelers?.filter((traveler, index) => {
      const hasAge = !!ages.find((age) => traveler.id === age.label);

      if (!hasAge && index === 0) {
        myselfDeleted = true;
        onClearMyselfAge();
      }
      return hasAge;
    }) || [];

  return {
    myselfDeleted,
    travelersFiltered,
  };
};

const updateTravelerAge =
  (
    travelersFiltered: StoreTraveler[],
    myselfDeleted: boolean,
    onClearMyselfAge: VoidFn
  ) =>
  (item: AgeSelectItem, index: number) => {
    const traveler: StoreTraveler = travelersFiltered.find(
      (traveler) => traveler.id === item.label
    ) || {
      ...DEFAULT_TRAVELER,
      age: item.value,
      id: item.label || uuid(),
    };

    if (traveler.birthday) {
      const age = differenceInYears(new Date(), traveler.birthday);
      const ageDifference = age - Number(item.value);

      if (ageDifference == 0) return traveler;

      if (index === 0 && !myselfDeleted) onClearMyselfAge();
      return { ...traveler, birthday: null, age: item.value };
    }
    return traveler;
  };

// Обновляем данные путешественников после обновления возрастов
export const updateTravelersAges = (
  travelers: StoreTraveler[],
  ages: AgeSelectItem[],
  onClearMyselfAge: VoidFn
): StoreTraveler[] => {
  // Удалем тревелера по удаленному возрасту
  const { travelersFiltered, myselfDeleted } = deleteTravelersByAges(
    travelers,
    ages,
    onClearMyselfAge
  );

  // Cбрасываем дату рождения и добавляем новых тревелеров
  return ages.map(
    updateTravelerAge(travelersFiltered, myselfDeleted, onClearMyselfAge)
  );
};

// Обновляем данные путешественников после обновления пд
export const updateTravelersData = (
  travelersData: Traveler[]
): StoreTraveler[] => {
  return travelersData.map((traveler) => {
    if (traveler?.birthday)
      return {
        ...traveler,
        age: String(differenceInYears(new Date(), traveler.birthday)),
      };
    return {
      ...traveler,
      age: '0',
    };
  });
};
