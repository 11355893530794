export const i18nDefaultValues = {
  WhoAndHowPaperwork: {
    title: 'Кто едет в путешествие',
    subTitle: 'Укажите фамилию и имя латиницей, как в заграничном паспорте',
    headers: {
      traveler: 'Путешественник',
    },
    labels: {
      delete: 'Удалить',
      lastNameLatin: 'Фамилия латиницей',
      firstNameLatin: 'Имя латиницей',
      birthday: 'Дата рождения',
      addMore: 'Добавить еще',
    },
    errors: {
      requiredField: 'Поле не заполнено',
      useLatin: 'Используйте буквы английского алфавита и символ «-»',
      maxLength: 'Максимальная длина 50 символов',
      incorrectAge: 'Возраст путешественника не соответствует условию продукта',
    },
  },
};
