export const RISKS_MOCK = [
  {
    code: 'Property_1',
    name: 'Пожар, удар молнии, взрыв',
    description:
      'Это базовый риск — мы всегда рекомендуем включать его в полис.',
    active: true,
    imageUrl:
      'https://storage.cloud.croc.ru:443/web-lk-pulse-dev-res/pics-mw/Risks/fire.png',
    binding: false,
    extendedDescription:
      'Компенсируем ущерб от пожара, а также ущерб нанесенный при его тушении. Например, выплатим сумму, необходимую для восстановления ремонта и возместим стоимость утраченных вещей',
    switchability: true,
  },
  {
    code: 'Property_2',
    name: 'Залив',
    description:
      'Этот риск не только базовый, но и самый популярный — ошибаются и соседи, и коммунальные службы.',
    active: false,
    imageUrl:
      'https://storage.cloud.croc.ru:443/web-lk-pulse-dev-res/pics-mw/Risks/water.png',
    binding: true,
    extendedDescription:
      'Возместим расходы на ремонт и восстановление вещей после залива водой или другими жидкостями. Например, в результате прорыва трубы, аварии систем отопления, протечки крыши и т. д.',
    switchability: true,
  },
  {
    code: 'Property_3',
    name: 'Противоправные действия третьих лиц',
    description:
      'Нет консьержа или сигнализации, низкий этаж? Рядом промзона или вокзал? Подключайте этот риск.',
    active: false,
    imageUrl:
      'https://storage.cloud.croc.ru:443/web-lk-pulse-dev-res/pics-mw/Risks/intruder.png',
    binding: true,
    extendedDescription:
      'Выплатим компенсацию за украденные из квартиры или дома вещи и технику или уничтоженную хулиганами мебель. Не распространяется на деньги, драгоценности, предметы искусства и т. д.',
    switchability: true,
  },
  {
    code: 'Property_4',
    name: 'Внешние воздействия',
    description:
      'Может выручить, если под окнами часто играют в футбол и другие активные игры с мячом.',
    active: false,
    imageUrl:
      'https://storage.cloud.croc.ru:443/web-lk-pulse-dev-res/pics-mw/Risks/falling_object.png',
    binding: false,
    extendedDescription:
      'Выплатим сумму, необходимую для восстановления имущества, если оно пострадало от наезда транспорта, падения летательных аппаратов или других внешних механических воздействий. Например, если дети с улицы разбили мячом окно, на балкон упало дерево или соседи повредили стену во время ремонта и т. д.',
    switchability: true,
  },
  {
    code: 'Property_5',
    name: 'Стихийные бедствия и чрезвычайные ситуации',
    description:
      'Актуально, если в регионе бушует стихия и сильные ветры, а под окнами большие деревья, фонари или рекламные щиты.',
    active: true,
    imageUrl:
      'https://storage.cloud.croc.ru:443/web-lk-pulse-dev-res/pics-mw/Risks/tornado.png',
    binding: false,
    extendedDescription:
      'Возместим убытки, если имущество пострадало во время стихийного бедствия. Например, в результате паводка затопило дом или квартиру и вещи в ней, или произошло землетрясение, которое разрушило дом',
    switchability: true,
  },
  {
    code: 'Property_6',
    name: 'Перепад напряжения',
    description:
      'Если они приведут к порче бытовой техники, вы получите выплату. Актуально для домов без автоматов и реле напряжения.',
    active: false,
    imageUrl:
      'https://storage.cloud.croc.ru:443/web-lk-pulse-dev-res/pics-mw/Risks/tv_high_voltage.png',
    binding: false,
    extendedDescription:
      'Компенсируем ущерб, нанесённый технике, если она сломалась в результате резких скачков напряжения в электросети. Например, оплатим ремонт или полную стоимость пострадавшей техники, но не больше 10% от общей страховой суммы движимого имущества',
    switchability: true,
  },
  {
    code: 'Property_7',
    name: 'Непредвиденные расходы',
    description:
      'Бываете в разъездах и опасаетесь, что поездку придется прервать из-за страхового случая? Возместим расходы на билеты.',
    active: false,
    imageUrl:
      'https://storage.cloud.croc.ru:443/web-lk-pulse-dev-res/pics-mw/Risks/wallet.png',
    binding: false,
    extendedDescription:
      'Компенсируем непредвиденные расходы в пределах 150 000 ₽. Например, если в результате страхового события пришлось менять замки, ключи, восстанавливать документы или тратиться на дорогу из другого города',
    switchability: true,
  },
  {
    code: 'Liability_1',
    name: 'Гражданская ответственность',
    description:
      'Чем выше этаж, тем актуальнее. Компенсируем ущерб соседям, если вы затопите их или нанесете другой ущерб.',
    active: false,
    imageUrl:
      'https://storage.cloud.croc.ru:443/web-lk-pulse-dev-res/pics-mw/Risks/neighbors.png',
    binding: true,
    extendedDescription:
      'Если от пожара, залива или другого внешнего воздействия пострадало имущество или здоровье соседей, компенсируем их убытки. Например, если повредилась стена или забор соседей во время ремонтных работ, была залита водой их квартира или пожар перекинулся на соседнее имущество',
    switchability: true,
  },
];
