import { getNumberOfDays } from '@shared/utils';
import i18n from '@app/i18n/config';

import { i18nDefaultValues } from '../i18n';

export const getAlerts = ({
  insurancePeriodOneTimeMax,
}: {
  insurancePeriodOneTimeMax: string;
}) => {
  return [
    {
      name: 'policy',
      title: i18n.t(
        'SMART:WhereAndHowLong.hints.numberDaysReduced',
        i18nDefaultValues.WhereAndHowLong.hints.numberDaysReduced
      ),
      description: i18n.t(
        'SMART:WhereAndHowLong.hints.exampleNumberDaysReduced',
        i18nDefaultValues.WhereAndHowLong.hints.exampleNumberDaysReduced
      ),
    },
    {
      name: 'isRussia',
      title: '',
      description: i18n.t(
        'SMART:WhereAndHowLong.hints.validWithinHundredKm',
        i18nDefaultValues.WhereAndHowLong.hints.validWithinHundredKm
      ),
    },
    {
      name: 'noChecked',
      title: '',
      description: i18n.t('SMART:WhereAndHowLong.hints.policyIsValid', {
        defaultValue: i18nDefaultValues.WhereAndHowLong.hints.policyIsValid,
        replace: {
          day: getNumberOfDays(insurancePeriodOneTimeMax) || 0,
        },
      }),
    },
    {
      name: 'isShengen',
      title: i18n.t(
        'SMART:WhereAndHowLong.hints.forSchengenCountries',
        i18nDefaultValues.WhereAndHowLong.hints.forSchengenCountries
      ),
      description: i18n.t(
        'SMART:WhereAndHowLong.hints.exampleForSchengenCountries',
        i18nDefaultValues.WhereAndHowLong.hints.exampleForSchengenCountries
      ),
    },
  ];
};
