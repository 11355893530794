import { media, styled } from '@pulse-web-ui/theme';

export const SportHasStyle = styled.div`
  flex: 1;
  padding-top: 24px;

  ${media.desktop} {
    padding: 0;
  }
`;
