import { styled, typography } from '@pulse-web-ui/theme';
import { Button } from '@pulse-web-ui/button';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CountIcon = styled.div`
  ${typography.regular16};
  color: #fff;
  width: 24px;
  height: 24px;
  background-color: #1698d9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DeleteTraveler = styled(Button)`
  color: #f3566a;
  line-height: 26px;
`;

export const TitleTraveler = styled.div`
  ${typography.medium20};
  color: #1f232b;
`;

export const NumberTraveler = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;
