import { differenceInYears } from 'date-fns';
import { v4 as uuid } from 'uuid';

import type { WhoAndHowValues } from '../who-and-how.type';
import { Traveler } from '@shared/types';

export const getDefaultValues = (
  values: WhoAndHowValues,
  travelers?: Traveler[],
  dateStart?: Date
) => {
  const travelersLocal = travelers || [];
  const hasTravelers =
    travelersLocal.length > 0 &&
    travelersLocal.every((traveler) => traveler.birthday);

  if (hasTravelers) {
    return {
      ...values,
      ageSelect: travelersLocal.map((traveler) => ({
        value: String(
          differenceInYears(
            dateStart || new Date(),
            traveler.birthday || new Date()
          )
        ),
        label: traveler.id || uuid(),
      })),
    };
  }

  return values;
};
