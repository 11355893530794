import { FooterSmartComponent } from '@entities/import-smart-components/footer-smart-component';
import { useGetRisksOptionsLength } from '@shared/hooks/use-get-risks-options-length';
import { Suspense } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@shared/hooks';
import { useUpdateFooter } from './use-update-footer';
import { FallbackSkeleton } from '@shared/components';
import type { FC } from 'react';
import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import type { AnalyticEventsMap } from '@shared/types';
import { FooterAnalyticEvent } from 'smart-components/footer-smart-component';

const analyticEventsMap: AnalyticEventsMap<FooterAnalyticEvent> = {
  [FooterAnalyticEvent.ON_OPEN]: {
    name: analyticEvents.travelSummary,
  },
  [FooterAnalyticEvent.ON_VIEW_OPTIONS]: {
    name: analyticEvents.travelViewOptionsFooter,
  },
};

export const FooterAdapter: FC = observer(() => {
  const {
    MainStore: {
      initProductStore: { initState },
      applicationStore: {
        setActiveStepByName,
        activeStep,
        flowConfig: { maxSteps },
      },
      productStore: {
        hasPricesCalcErr,
        currency,
        formState,
        fullRisksOptionsLength,
      },
    },
  } = useStores();

  const risks = formState?.WantImprovePolicy?.fullOptionsRisks;
  const coverageSum = formState?.WhoAndHow?.coverageSum;
  const fullOptionsRisks = formState.WantImprovePolicy?.fullOptionsRisks;
  const initStateRisks = initState?.risks;

  const value = useUpdateFooter();
  const { formattedOptionsStringFooter } = useGetRisksOptionsLength(
    fullRisksOptionsLength,
    fullOptionsRisks
  );

  const handleAnalyticEventSend =
    useHandleSmartComponentsAnalyticEvents<FooterAnalyticEvent>(
      analyticEventsMap
    );

  return (
    <Suspense fallback={<FallbackSkeleton height={60} />}>
      <FooterSmartComponent
        currency={currency}
        coverageSum={coverageSum}
        risks={risks}
        value={value}
        setActiveStepByName={setActiveStepByName}
        activeStep={activeStep}
        hasPricesCalcErr={hasPricesCalcErr}
        initStateRisks={initStateRisks}
        selectedRisksLabel={formattedOptionsStringFooter}
        maxSteps={maxSteps}
        onAnalyticEventSend={handleAnalyticEventSend}
      />
    </Suspense>
  );
});

FooterAdapter.displayName = 'FooterAdapter';
