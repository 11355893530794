import { Main } from '@app/store';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import type {
  OtpResponseType,
  AuthTokensType,
  ProfileLockedFields,
} from '@shared/types';

type authResponseType =
  | {
      confirmationId: string;
      authenticationToken: string;
    }
  | undefined;

export class AuthStore {
  private mainStore: Main;

  callOtp: string = '';
  promoAgreement: boolean = false;
  callAuth: string = '';
  hasPhone: boolean = true;
  confirmationId: string | undefined = undefined;
  authenticationToken: string | undefined = undefined;
  accessToken: string | undefined = undefined;
  profile: any = undefined;
  authTokens?: AuthTokensType | undefined = undefined;
  otpIsLoading: boolean = false;
  authIsLoading: boolean = false;
  externalAuthPhone?: string;
  disableForm: boolean = false;
  hasAuthorized: boolean = false;
  initAuthState: boolean = true;
  refetchAuthentication: boolean = false;
  authError: boolean = false; // Дизейблит форму если пришла ошибка после получения ОТП
  otpErrorCode: boolean = false; // Если пришла ошибка от бека
  useOtp: boolean = false;
  disableProfileState: ProfileLockedFields = {}; // Стейт блокировки полей профиля

  constructor(mainStore: Main) {
    this.mainStore = mainStore;
    makeAutoObservable(
      this,
      {
        callOtp: observable,
        callAuth: observable,
        hasPhone: observable,
        confirmationId: observable,
        authenticationToken: observable,
        profile: observable,
        otpIsLoading: observable,
        authIsLoading: observable,
        externalAuthPhone: observable,
        disableForm: observable,
        hasAuthorized: observable,
        initAuthState: observable,
        authTokens: observable,
        refetchAuthentication: observable,
        authError: observable,
        otpErrorCode: observable,
        useOtp: observable,
        disableProfileState: observable,
        setUseOtp: action,
        setOtpErrorCode: action,
        handleOtp: action,
        handleAuth: action,
        handleHasPhone: action,
        handleStoreAuthResponse: action,
        handleOtpResponse: action,
        setOtpIsLoading: action,
        setAuthIsLoading: action,
        setExternalAuthPhone: action,
        setDisableForm: action,
        setHasAuthorized: action,
        setInitAuthState: action,
        setAuthTokens: action,
        setAuthError: action,
        logOut: action,
        handleUpdateDisableProfileState: action,
      },
      { autoBind: true }
    );
  }

  handleUpdateDisableProfileState = (e: ProfileLockedFields) => {
    this.disableProfileState = e;
  };

  handleOtp = (e: string, i: boolean) => {
    runInAction(() => {
      this.callOtp = e;
      this.promoAgreement = i;
    });
  };

  handleAuth = (e: string) => {
    this.callAuth = e;
    this.callOtp = '';
  };

  handleHasPhone = (e: boolean) => {
    this.hasPhone = e;
  };

  handleProfile = (e: any) => {
    this.profile = e;
  };

  setOtpIsLoading = (e: boolean) => {
    this.otpIsLoading = e;
  };

  setAuthIsLoading = (e: boolean) => {
    this.authIsLoading = e;
  };

  setDisableForm = (e: boolean) => {
    this.disableForm = e;
  };

  setExternalAuthPhone = (e?: string) => {
    this.externalAuthPhone = e;
  };

  handleStoreAuthResponse = (e: authResponseType) => {
    runInAction(() => {
      this.confirmationId = e?.confirmationId || undefined;
      this.authenticationToken = e?.authenticationToken || undefined;
    });
  };

  setUseOtp = (e: boolean) => {
    this.useOtp = e;
  };

  setAuthError = (e: boolean) => {
    this.authError = e;
  };

  setRefetchAuthentication = (e: boolean) => {
    this.refetchAuthentication = e;
  };

  setOtpErrorCode = (e: boolean) => {
    this.otpErrorCode = e;
  };

  handleOtpResponse = (e: OtpResponseType) => {
    runInAction(() => {
      if (e) {
        this.authTokens = {
          authorization: {
            accessToken: e.accessToken,
            csrfToken: e.csrfToken,
            isVip: e.isVip,
            userId: e.userId,
            name: e.name,
            phone: this.callAuth,
          },
        };
      }
      this.setUseOtp(false);
      this.accessToken = e?.accessToken;
    });
  };

  setHasAuthorized = (e: boolean) => {
    this.hasAuthorized = e;
  };

  setInitAuthState = (e: boolean) => {
    this.initAuthState = e;
  };

  setAuthTokens = (e: AuthTokensType) => {
    runInAction(() => {
      this.authTokens = e;
      this.accessToken = e?.authorization.accessToken;
    });
  };

  logOut = () => {
    runInAction(() => {
      this.handleProfile(undefined);
      this.handleStoreAuthResponse(undefined);
      this.setAuthTokens(undefined);
      this.setDisableForm(false);
      this.handleOtp('', false);
      this.handleAuth('');
      this.setHasAuthorized(false);
    });
  };
}
