import { RefObject, useEffect } from 'react';

export const useAdjustBodyHeight = (
  isOpenModal: boolean,
  modalRef: RefObject<HTMLDivElement>
) => {
  useEffect(() => {
    const adjustBodyHeight = () => {
      if (isOpenModal) {
        if (modalRef?.current) {
          const bodyHeight = document.body.getBoundingClientRect().height;
          const modalHeight = modalRef.current?.offsetHeight;

          if (modalHeight > bodyHeight) {
            document.body.style.height = `${modalHeight}px`;
          }
        }
      } else if (document.body.style.height) {
        document.body.style.removeProperty('height');
      }
    };

    const timeoutId = setTimeout(adjustBodyHeight, 100);

    return () => clearTimeout(timeoutId);
  }, [isOpenModal]);
};
