import { media, styled } from '@pulse-web-ui/theme';

export const Form = styled.div`
  background: #fff;
  padding: 20px 16px;
  border-radius: 24px;

  ${media.desktop} {
    padding: 24px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  ${media.desktop} {
    row-gap: 24px;
  }
`;

export const WarningWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 24px;

  ${media.desktop} {
    margin-top: 32px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  column-gap: 0;
  ${media.desktop} {
    column-gap: 16px;
  }
`;
