import { useQuery } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

import type { Url } from '@shared/types';

import { useQueryFunction } from './use-query-function';

export const useDownloadPdf = (
  key: string,
  url: Url,
  authToken: string,
  deps?: any[]
) => {
  const headers = {
    'X-Process-ID': uuidv4(),
    Accept: 'application/json',
    'Content-Type': 'application/pdf',
  };

  let authHeaders = {
    ...headers,
    Authorization: '',
  };

  if (authToken) {
    authHeaders = {
      ...headers,
      Authorization: `Bearer ${authToken}`,
    };
  }

  const queryFn = useQueryFunction('post', `/v1/file/${url}`, {}, '', {
    responseType: 'arraybuffer',
    headers: authToken ? authHeaders : headers,
  });

  const { isLoading, error, data, refetch } = useQuery([key, deps], queryFn, {
    enabled: false,
  });

  let res;

  if (!isLoading && data?.data) {
    res = data.data;
  }

  return { isLoading, error, res, refetch };
};
