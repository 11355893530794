import type { RisksResponse } from '@shared/queries';
import type {
  GetActivitiesResponse,
  GetCountryResponse,
  InsuranceLimitStart,
  IFLFlatInsuranceProduct,
} from '@shared/types';
import { action, makeAutoObservable, observable } from 'mobx';

const initInsuranceStartLimit: InsuranceLimitStart = {
  dateEndFranchise: '',
  effectiveSinceFranchise: '',
  endDate: new Date().toISOString(),
  insurancePeriodAnnual: [0],
  insurancePeriodOneTimeMax: '',
  insurancePeriodOneTimeMin: '',
  insurancePeriodShortMax: '',
  insurancePeriodShortMin: '',
  startDate: new Date().toISOString(),
};

type InitStateType = Partial<IFLFlatInsuranceProduct> &
  Partial<GetCountryResponse> &
  Partial<RisksResponse> &
  Partial<GetActivitiesResponse>;

export class InitProductStore {
  initState: InitStateType = {}; // Начальные свойства объекта при инициализации приложения
  insuranceLimitStart: InsuranceLimitStart = initInsuranceStartLimit;
  regUtm?: Record<string, any> = undefined; // Метки передаваемые в урл (аналитика)
  initOrder = undefined; // Данные после успешного ответа от init-order
  formBuilderIsMounted = false; // Признак монтирования форм билдера, чтобы задавать начальные данные формы после монтирования
  isInitSessionRecovery = true; // Флаг, указывающий на необходимость запуска восстановления сессии

  constructor() {
    makeAutoObservable(this, {
      initState: observable,
      setInitState: action,
      insuranceLimitStart: observable,
      setInsuranceLimitStart: action,
      initOrder: observable,
      setInitOrder: action,
      regUtm: observable,
      setRegUtm: action,
      formBuilderIsMounted: observable,
      setFormBuilderIsMounted: action,
    });
  }

  setInitState = (e: any) => {
    this.initState = {
      ...e,
    };
  };

  setInsuranceLimitStart = (e: InsuranceLimitStart) => {
    this.insuranceLimitStart = e;
  };

  setRegUtm = (e: Record<string, any>) => {
    this.regUtm = e;
  };

  setInitOrder = (e: any) => {
    this.initOrder = e;
  };

  setFormBuilderIsMounted = () => {
    this.formBuilderIsMounted = true;
  };

  setIsInitSessionRecovery = (e: boolean) => {
    this.isInitSessionRecovery = e;
  };
}
