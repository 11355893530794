export const i18nDefaultValues = {
  OptionsList: {
    labels: {
      basicProtect: 'Базовая защита',
      payment: 'Организуем и оплатим',
      compensateExpenses: 'Возместим расходы',
      additionalProtect: 'Дополнительная защита',
      changeOptions: 'Изменить опции',
      understand: 'Понятно',
    },
    hints: {
      organizeAndPay:
        'Где бы вы не находились, наш сотрудник организует вам необходимое лечение.\n Вам не нужно думать про деньги - все расходы будут оплачены нами в пределах суммы страховки',
    },
  },
};
