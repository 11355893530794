/* eslint-disable indent */
import type { FC } from 'react';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@pulse-web-ui/button';

import { useTimer } from '@shared/hooks';

import {
  Resend as StyledResend,
  ResentText as StyledResendText,
} from './resend.style';
import { ResendProps } from './resend.types';
import { i18nDefaultValues } from '../../i18n';

const Resend: FC<ResendProps> = ({
  expiryTimestamp,
  onResend,
  onAbleResendOtp,
  isExpiryTimestampText,
}) => {
  const { t } = useTranslation();
  const { seconds, restart } = useTimer({
    expiryTimestamp,
  });

  const handleResend = useCallback(() => {
    onResend(restart);
  }, [onResend, restart]);

  useEffect(() => {
    if (seconds <= 0 && onAbleResendOtp) {
      onAbleResendOtp();
    }
  }, [seconds, onAbleResendOtp]);

  useEffect(() => restart(expiryTimestamp), [expiryTimestamp]);

  const secondsRemaining = (
    <span>{`${t('SMART:Submit.hints.getNewCode', {
      defaultValue: i18nDefaultValues.Submit.hints.getNewCode,
    })}${
      seconds > 0
        ? ` ${t('SMART:Submit.hints.through', {
            defaultValue: i18nDefaultValues.Submit.hints.through,
          })} 0:${seconds < 10 ? '0' + seconds : seconds}`
        : ''
    }`}</span>
  );

  const handleResetClick = () => {
    if (seconds > 0) {
      return null;
    } else {
      return handleResend();
    }
  };

  return (
    <>
      {isExpiryTimestampText ? (
        <StyledResendText seconds={seconds} onClick={handleResetClick}>
          {secondsRemaining}
        </StyledResendText>
      ) : (
        <StyledResend>
          <Button variant="text" onClick={handleResend} disabled={seconds > 0}>
            {secondsRemaining}
          </Button>
        </StyledResend>
      )}
    </>
  );
};

export default memo(Resend);
