import { AxiosError } from 'axios';

export type { ConfirmPaymentType } from './payment';
export type { SmartComponent, BaseComponent } from './component.type';
export { Currency } from './currency';
export type {
  RisksOptionsType,
  Option,
  Risk,
  RadioItem,
  Slider,
  RisksType,
} from './risk-options.types';

export { RisksOptionEnum } from './risk-options.types';

export type { InitSportItem, GetActivitiesResponse } from './sport.types';
export type { FormBuilderStructure } from './form-builder-structure.types';
export type { Territory, GetCountryResponse } from './territory.types';
export type { AgeRange } from './age-range.types';
export type { AgeRatio } from './age-ratio.types';
export type { InsuranceLimitStart } from './insurance-limit-start.types';
export type { AgeSelect } from './age-select.types';
export type { Traveler, StoreTraveler } from './traveler.types';
export type { InitOrderResponse } from './init-order.types';
export type {
  AnalyticEventsMap,
  BaseEventType,
} from './analytic-events-map.types';

export interface ThemeConfig {
  'theme-url'?: string;
  'texts-url'?: string;
  'auth-url'?: string;
  'icons-url'?: string;
  'flow-url'?: string;
}

export type AuthFlow = {
  partnerAuthRegType: string;
  sepScrAuth: boolean;
  stepAuth: number;
  product: string;
};

export type Method = 'post' | 'get' | 'patch' | 'put';
export type Url = string;

export interface InsuranceProduct {
  code: string;
  maxProductLimit: string;
  minProductLimit: string;
  name: string;
}

export interface IFLFlatInsuranceProduct extends InsuranceProduct {
  maxObjectsNumber: number;
  step: string;
}

export type CurrencyType = 'USD' | 'EUR';

export enum ClientDocumentType {
  MEMO = 'Memo',
  RULES = 'Rules',
  CONTRACT = 'Contract',
  KEY_INFO_DOCUMENT = 'KeyInfoDocument',
}

export type DocumentType = {
  clientDocument: string;
  clientDocumentType: ClientDocumentType;
  isNew?: null;
  token?: string;
  index?: number;
};

export type ContractDataType = {
  amount?: string | number;
  contractId?: string;
  contractNumber?: string;
  email?: string | null;
  firstName?: string;
  lastName?: string;
  patronymic?: string;
  operationType: 'ORDER';
  orderNumber?: string;
  paymentDocumentId?: string;
  subscriptionId?: string;
  subscriptionType?: string;
};

export type ConfirmAuth = {
  accessToken: string;
  isVip: boolean;
  userId: string;
  name: string;
  csrfToken: string;
};

export type Registration = {
  authorization: {
    accessToken: string;
    csrfToken: string;
  };
  isVip: boolean;
};

export type OtpResponseType =
  | {
      accessToken: string;
      isVip: boolean;
      userId: string;
      name: string;
      csrfToken: string;
    }
  | undefined;

export type AuthTokensType =
  | {
      authorization: {
        accessToken: string;
        csrfToken: string;
        isVip: boolean;
        userId: string;
        name: string;
        phone: string;
      };
    }
  | undefined;

export type FormInsuranceContactsProps = {
  firstName: string;
  lastName: string;
  middleName?: string;
  email: string;
  birthDate: string;
};

export type Country = {
  value: string;
  label: string;
};
export type ProductPrice = {
  promoCode: string;
  premiumAndDelta: string;
  premiumAndDeltaPromo?: string;
  isSuccessfulPromo?: boolean;
};

export enum KeyCode {
  ENTER = 13,
}

export type ResponseError = AxiosError<{ code?: string }>;

export type ProfileLockedFields = {
  name?: boolean;
  surname?: boolean;
  secondName?: boolean;
  birthDate?: boolean;
};
