import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { StateBlock } from '@pulse-web-ui/state-block';
import { Modal } from '@pulse-web-ui/modal';
import { observer } from 'mobx-react-lite';
import { useStores } from '@shared/hooks';

type GlobalErrorType = {
  showErr: boolean;
};

const InfoIcon = lazy(() => import('@shared/icons/info'));

export const GlobalError = observer(({ showErr }: GlobalErrorType) => {
  const {
    MainStore: {
      errorStore: { setErrorRetry },
    },
  } = useStores();
  const { t } = useTranslation();

  if (!showErr) return;

  const errorHandler = () => {
    setErrorRetry(true);
  };

  //  TODO: внести в словарь текста
  return (
    <Modal showDialog={showErr} closeIcon={false} dangerouslyBypassScrollLock>
      <StateBlock
        title={t('COMMON:headers.globalError')}
        icon={
          <Suspense>
            <InfoIcon />
          </Suspense>
        }
        submitButtonText={t('COMMON:labels.tryAgain')}
        onSubmit={errorHandler}
      />
    </Modal>
  );
});
