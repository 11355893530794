import { RadioCardGroup } from '@pulse-web-ui/radio-card-group';
import { MoneyRangeInput } from '@pulse-web-ui/range-input';
import { Currency } from '@shared/constants';
import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledRadioLabel } from '../want-improve-policy.styles';
import { ContentProps } from '../want-improve-policy.types';
import { i18nDefaultValues } from '../i18n';

export const Content: FC<ContentProps> = ({
  item: { isSlider, slider, name, sum, checked, isRadio, items },
  onChange,
  currency,
  hasFocus,
}) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState<number>(sum as number);

  const [debouncedInputValue, setDebouncedInputValue] = useState<number>(
    sum as number
  );

  const inputRef = useRef<any>(null);

  const handleInputFocus = useCallback(() => {
    if (hasFocus && inputRef.current) {
      // TODO: проблема выхода на инпут через ref, доработать компонент moneyRangeInput
      inputRef.current.querySelector('input').focus();
    }
  }, [hasFocus]);

  const handleRangeChange = useCallback((val: number) => {
    setInputValue(val);
  }, []);

  useEffect(() => {
    handleInputFocus();
  }, [handleInputFocus]);

  // TODO: Согласовать время задержки для ползунка
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, 400);
    return () => clearTimeout(timeoutId);
  }, [inputValue, 400]);

  useEffect(() => {
    onChange(debouncedInputValue, name);
  }, [debouncedInputValue]);

  if (isSlider && slider) {
    return (
      <>
        <StyledRadioLabel>
          {t('SMART:WantImprovePolicy.labels.travelCostCheck', {
            defaultValue:
              i18nDefaultValues.WantImprovePolicy.labels.travelCostCheck,
          })}
        </StyledRadioLabel>
        <div ref={inputRef}>
          <MoneyRangeInput
            currency={currency}
            min={slider.min}
            max={slider.max}
            step={1}
            onChange={handleRangeChange}
            value={inputValue as number}
            disabled={!checked}
            name="input"
          />
        </div>
      </>
    );
  }

  if (isRadio) {
    return (
      <>
        <StyledRadioLabel>
          {t('SMART:WantImprovePolicy.labels.sumInsured', {
            defaultValue: i18nDefaultValues.WantImprovePolicy.labels.sumInsured,
          })}
        </StyledRadioLabel>
        <RadioCardGroup
          name={name}
          items={items}
          value={sum ? String(sum) : items[0].value}
          onChange={(event) => {
            const { value } = event.target as HTMLInputElement;
            onChange(Number(value), name);
          }}
          currency={currency === Currency.USD ? Currency.USD : Currency.EURO}
          desktopWidth={70}
        />
      </>
    );
  }

  return null;
};
