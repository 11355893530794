import { media, styled, typography } from '@pulse-web-ui/theme';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px 16px;
  border-radius: 24px;
  row-gap: 0;

  ${media.desktop} {
    row-gap: 24px;
    padding: 24px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;

  ${media.desktop} {
    flex-direction: row;
    gap: 16px;
    margin-top: 0;
  }
`;

export const RowSection = styled.div`
  display: flex;
  flex-basis: 50%;
  & > * {
    width: 100%;
  }

  ${media.desktop} {
    &:first-child {
      padding-bottom: 0;
    }
  }
`;

export const StyledLabel = styled.label`
  ${typography.medium20}
`;

export const WidgetSubHeaderText = styled.p`
  ${typography.regular16}
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: 16px;
`;
