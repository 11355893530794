import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { assignRef, useCallbackRef } from 'use-callback-ref';

import { useRequest, useStores } from '@shared/hooks';
import { Endpoints, QUERY_KEYS } from '@shared/constants';
import { OPERATION_ID } from '@shared/operations';

import type {
  CompletePaymentRequest,
  CompletePaymentResponse,
} from './complete-payment.types';
import { PublicRoutes } from '@shared/routes';

export const useCompletePayment = (data: CompletePaymentRequest) => {
  const {
    MainStore: {
      errorStore: { setErrorRetry },
      authStore: { accessToken },
      productStore: { orderData },
    },
  } = useStores();

  const navigate = useNavigate();
  const completePaymentData = { ...data };

  const { res, isLoading, refetch } = useRequest<CompletePaymentResponse>(
    QUERY_KEYS.completePayment,
    'post',
    Endpoints.COMPLETE_PAYMENT,
    completePaymentData,
    [completePaymentData, accessToken],
    true,
    accessToken
  );

  const onRefPaymentSuccessFailUpdate = useCallback((newValue: any) => {
    if (newValue && newValue !== null) {
      const parsedValue = JSON.parse(newValue);
      if (parsedValue === false) {
        localStorage.removeItem('referenceSuccess');
        localStorage.removeItem('operationId');
        localStorage.removeItem('paymentSuccess');
        navigate(PublicRoutes.FAILED_PAY);
      }
    }
  }, []);

  const onRefOperationIdUpdate = useCallback(
    (newValue: any) => {
      if (newValue && newValue !== null) {
        completePaymentData.operationId = newValue;

        if (
          !!completePaymentData?.id &&
          localStorage.getItem('referenceSuccess') === orderData?.orderNumber
        ) {
          refetch().then((res) => {
            const isCompleted =
              res.data?.data?.operationStatus === OPERATION_ID.COMPLETE;

            isCompleted
              ? navigate(PublicRoutes.SUCCESS_PAY)
              : navigate(PublicRoutes.IN_PROGRESS_PAY);
          });
        }
      }
    },
    [completePaymentData]
  );

  const refPaymentDeclineId = useCallbackRef<null | string>(
    null,
    onRefPaymentSuccessFailUpdate
  );

  const refSOperationId = useCallbackRef<null | string>(
    null,
    onRefOperationIdUpdate
  );

  // Подписка на результат оплаты от В2Р
  const storageListener = () => {
    if (
      localStorage.getItem('operationId') !== null &&
      refSOperationId.current !== localStorage.getItem('operationId')
    ) {
      assignRef(refSOperationId, String(localStorage.getItem('operationId')));
    }

    if (localStorage.getItem('paymentSuccess') !== null) {
      const paymentResult = JSON.parse(
        localStorage.getItem('paymentSuccess') as string
      );

      if (paymentResult === false) {
        assignRef(refPaymentDeclineId, String(paymentResult));
      }
    }
  };

  window.addEventListener('storage', storageListener);

  useEffect(() => {
    return () => {
      window.removeEventListener('storage', storageListener);
    };
  }, []);

  useEffect(() => {
    if (!isLoading && res) {
      setErrorRetry(false);
      assignRef(refSOperationId, undefined);
    }
  }, [isLoading, res]);

  return refetch;
};
