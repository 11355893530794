import {
  // analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { SmartStartDate } from '@entities/import-smart-components/smart-start-date';
import type {
  StartDateOptions,
  StartDateValues,
} from '@pulse-smart-components-kit/start-date';
import { StartDateAnalyticEvent } from '@pulse-smart-components-kit/start-date/lib/constants';
import { FallbackSkeleton } from '@shared/components';
import { useStores } from '@shared/hooks';
import type { AnalyticEventsMap } from '@shared/types';
import { observer } from 'mobx-react-lite';
import { Suspense, useMemo, forwardRef, type Ref, FC } from 'react';
import type { SmartComponentAdapterProps } from 'smart-components';
import { formStartDateSchema } from './schema';

const analyticEventsMap: AnalyticEventsMap<StartDateAnalyticEvent> = {
  [StartDateAnalyticEvent.ON_DATE_SELECT]: {
    // name: analyticEvents.travelViewOptionsOptions,
    name: '', // TODO replace mock data
  },
};

export const StartDateAdapter: FC<SmartComponentAdapterProps<StartDateValues>> =
  observer(
    forwardRef(
      (
        { value, onChange, isSubmitting, fieldState },
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const {
          MainStore: {
            authStore: { disableForm },
          },
        } = useStores();

        const options: StartDateOptions = useMemo(
          () => ({
            minDate: new Date(), // TODO replace mock data
            isControlsHidden: true,
          }),
          []
        );

        const handleAnalyticEventSend =
          useHandleSmartComponentsAnalyticEvents<StartDateAnalyticEvent>(
            analyticEventsMap
          );

        return (
          <Suspense fallback={<FallbackSkeleton height={460} />}>
            <SmartStartDate
              ref={forwardRef}
              value={value}
              onChange={onChange}
              isSubmitting={isSubmitting}
              options={options}
              disabled={disableForm}
              fieldState={fieldState}
              formSchema={formStartDateSchema}
              onAnalyticEventSend={handleAnalyticEventSend}
            />
          </Suspense>
        );
      }
    )
  );

StartDateAdapter.displayName = 'StartDateAdapter';
