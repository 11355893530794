/* eslint-disable indent */
import { RisksOptionsType } from '@shared/types';
import { useEffect, useMemo, useState } from 'react';
import { pluralization } from '@shared/utils';
import i18n from '@app/i18n/config';

import { i18nDefaultValues } from '../../smart-components/footer-smart-component/i18n';

export const useGetRisksOptionsLength = (
  fullRisksOptionsLength: number,
  fullOptionsRisks: RisksOptionsType[]
) => {
  const [riskOptionsLength, setRisksOptionsLength] = useState(
    fullRisksOptionsLength
  );

  const serviceDeclensions = useMemo(
    () => [
      i18n.t(
        'SMART:FooterSmartComponent.declensions.service',
        i18nDefaultValues.FooterSmartComponent.declensions.service
      ),
      i18n.t(
        'SMART:FooterSmartComponent.declensions.service2',
        i18nDefaultValues.FooterSmartComponent.declensions.service2
      ),
      i18n.t(
        'SMART:FooterSmartComponent.declensions.service3',
        i18nDefaultValues.FooterSmartComponent.declensions.service3
      ),
    ],
    []
  );

  const optionDeclensions = useMemo(
    () => [
      i18n.t(
        'SMART:FooterSmartComponent.declensions.option',
        i18nDefaultValues.FooterSmartComponent.declensions.option
      ),
      i18n.t(
        'SMART:FooterSmartComponent.declensions.option2',
        i18nDefaultValues.FooterSmartComponent.declensions.option2
      ),
      i18n.t(
        'SMART:FooterSmartComponent.declensions.option3',
        i18nDefaultValues.FooterSmartComponent.declensions.option3
      ),
    ],
    []
  );

  useEffect(() => {
    setRisksOptionsLength(fullRisksOptionsLength);
  }, [fullRisksOptionsLength]);

  useEffect(() => {
    if (fullOptionsRisks) {
      const checkedOptionsRisksLength = fullOptionsRisks.filter(
        (values: any) => values.checked
      ).length;

      const optionsRisksFinalLength =
        fullRisksOptionsLength + checkedOptionsRisksLength;
      setRisksOptionsLength(optionsRisksFinalLength);
    }
  }, [fullOptionsRisks, fullRisksOptionsLength]);

  const formattedOptionsString = `${
    riskOptionsLength
      ? `${riskOptionsLength} ${pluralization(
          riskOptionsLength,
          optionDeclensions
        )}`
      : `${0} ${optionDeclensions[2]}`
  }`;

  const formattedOptionsStringFooter = `${
    riskOptionsLength
      ? `${riskOptionsLength} ${pluralization(
          riskOptionsLength,
          serviceDeclensions
        )}`
      : `${0} ${serviceDeclensions[2]}`
  }`;

  return {
    riskOptionsLength,
    formattedOptionsString,
    formattedOptionsStringFooter,
  };
};
