import { GetRiskRequest, RisksResponse } from './types';
import { QUERY_KEYS, Endpoints } from '@shared/constants';
import { useRequest } from '@shared/hooks';

export const useGetRisks = (data: GetRiskRequest) => {
  return useRequest<RisksResponse>(
    QUERY_KEYS.getRisk,
    'post',
    Endpoints.GET_RISKS,
    data,
    [],
    true
  );
};
